import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { orderBy, map } from 'lodash-es';

import VirtualList from 'react-tiny-virtual-list';

const useStyles = createUseStyles({
    table: {},
    thead: {
        width: props => `calc(100% - ${props.scrollbarWidth}px)`,
        height: props => props.theadHeight || props.rowHeight,
        paddingLeft: props => props.rowPadding,
        paddingRight: props => props.rowPadding,
    },
    tbody: {
        width: '100%',
        overflowScrolling: 'touch',
        '&::-webkit-scrollbar': {
            width: props => props.scrollbarWidth,
            background: 'transparent',
        },
        '&::-webkit-scrollbar-track': {
            display: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#cbcbcb',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#909090',
        },
    },
    row: {
        position: 'relative', //tooltip can't be relative to overflow:hidden column
        width: '100%',
        height: '100%',
        paddingLeft: props => props.rowPadding ? props.rowPadding : undefined,
        paddingRight: props => props.rowPadding ? props.rowPadding : undefined,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: [1, 'solid', '#ccc'],
        // '&:not(:last-child)': {
        //     borderBottom: [1, 'solid', '#ccc'],
        // },
    },
    th: {
        extend: 'td',
        cursor: props => props.sortable ? 'pointer' : 'default',
        userSelect: 'none',
        paddingRight: props => props.columnPadding,
        '& > small': {
            marginLeft: 3, //spacing between heading and up/down arrow
        },
        //do not truncate with ellipsis
        whiteSpace: 'initial',
        overflow: 'initial',
        textOverflow: 'initial',
        display: 'flex', //align th and sorter arrow
        alignItems: 'center',
    },
    td: {
        flex: 1,
        minWidth: 0,
        height: '100%',
        display: 'grid',
        alignItems: 'center',
        //truncate with ellipsis
        whiteSpace: 'nowrap',
        // overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: props => props.columnPadding,
    },
    actionsColumn: {
        textAlign: 'right',
        flexBasis: ({ columnsWidths }) => (100 - Object.values(columnsWidths).reduce((sum, value) => sum + value, 0)) + '%',
    },
    rowClickable: {
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0 0 6px rgba(0,0,0,0.16), 0 0 6px rgba(0,0,0,0.23)',
        },
    },
    columnClickable: {
        cursor: 'pointer',
    },
    left: {
        justifyContent: 'flex-start',
        textAlign: 'left',
    },
    center: {
        justifyContent: 'center',
        textAlign: 'center',
    },
    right: {
        justifyContent: 'flex-end',
        textAlign: 'right',
    },
});

const Grid = ({
    id,
    actionsLabel,
    className,
    classNames = {},
    data = [],
    sortable = true,
    columns = [],
    columnPadding = 0,
    rowPadding = 0,
    columnsNames = {},
    columnsWidths = {},
    columnsAlignments = {},
    columnsFormatters = {},
    columnsTooltips = {},
    columnsClasses = {},
    headerFormatters = {},
    onColumnClick = {},
    overscan = 100,
    defaultSorters = {},
    customSorter,
    rowActionsRenderer,
    rowHeight = 40,
    theadHeight = 40,
    upperTheadHeight = 40,
    onRowClick,
    updateData = Function.prototype,
    scrollbarWidth = 5,
}) => {
    const classes = useStyles({ rowHeight, theadHeight, sortable, rowActionsRenderer, columnsWidths, columnPadding, rowPadding, scrollbarWidth });

    const [availableHeight, setAvailableHeight] = useState(400);
    const [sorters, setSorters] = useState(defaultSorters);

    useEffect(() => {
        const availableHeight = document.getElementById(id).parentElement.offsetHeight;
        setAvailableHeight(availableHeight);
    }, []);

    useEffect(() => {
        const sortedData = !customSorter ? orderBy(data, Object.keys(sorters), Object.values(sorters)) : customSorter(data);
        updateData ? updateData(sortedData) : console.error('sorters will not have effect unless updateData is provided');
    }, [sorters]);

    const setSorter = key => event => {
        const previousSorters = { ...sorters };
        const keep = event.shiftKey;

        const nextSorters = keep ? { ...previousSorters } : { [key]: previousSorters[key] };

        if (!nextSorters[key])
            nextSorters[key] = 'desc';
        else if (nextSorters[key] === 'desc')
            nextSorters[key] = 'asc';
        else
            delete nextSorters[key];

        setSorters(nextSorters);
    };

    const rowsVisible = Math.floor(availableHeight / rowHeight);
    const tbodyAvailableHeight = availableHeight - theadHeight - upperTheadHeight;
    const tbodyHeight = (data.length * rowHeight) >= tbodyAvailableHeight ? tbodyAvailableHeight : (data.length * rowHeight);
    // console.log(`availableHeight=${availableHeight} tbodyAvailableHeight=${tbodyAvailableHeight} rowsVisible=${rowsVisible} rowsExisting=${data.length} => tbodyHeight=${tbodyHeight}`);

    const renderRow = ({ index, style }) => {
        const item = data[index];

        const rowClassName = typeof classNames.row === 'function' ? classNames.row(item) : classNames.row;

        return (
            <div data-row key={index} className={cx(classes.row, rowClassName, onRowClick ? classes.rowClickable : null)} style={style} onClick={onRowClick ? onRowClick(item) : Function.prototype}>
                { map(columns, column => {
                    const value = columnsFormatters[column] ? columnsFormatters[column](item[column], item) : item[column];
                    const onClick = onColumnClick[column] ? onColumnClick[column](item[column], item) : Function.prototype;
                    const title = columnsTooltips[column] ? columnsTooltips[column](value, item) : typeof value == 'string' ? value : '';
                    const className = cx(classes.td, classNames.td, onColumnClick[column] && classes.columnClickable, classes[columnsAlignments[column]], columnsClasses[column] && columnsClasses[column](item[column], item));
                    const style = { flexBasis: `${columnsWidths[column]}%` };

                    return <div key={column} className={cx(className, classNames.td)} style={style} onClick={onClick} title={title}>{value}</div>;
                }) }
                { !!rowActionsRenderer &&
                <div data-td data-td-actions className={cx(classes.td, classes.actionsColumn, classNames.td, classNames.tdActions)}>
                    { React.createElement(rowActionsRenderer, { ...item, rowIndex: index, rowVirtualIndex: index % rowsVisible, rowsVisible }) }
                </div>
                }
            </div>
        );
    };

    return (
        <div id={id} className={cx(classes.table, className, classNames.table)}>
            <div className={cx(classes.thead, classNames.thead, classes.row, classNames.row)}>
                { map(columns, column => (
                    <div key={column} className={cx(classes.th, classNames.th, classes[columnsAlignments[column]])} style={{ flexBasis: `${columnsWidths[column]}%` }} onClick={sortable ? setSorter(column) : Function.prototype}>
                        <span>
                            {headerFormatters[column] || columnsNames[column] || column}
                        </span>
                        <small>
                            {sorters[column] === 'desc' && '↓'}
                            {sorters[column] === 'asc' && '↑'}
                        </small>
                    </div>
                )) }
                { !!rowActionsRenderer &&
                <div className={cx(classes.th, classes.actionsColumn, classNames.thActions)}>{actionsLabel}</div>
                }
            </div>

            <VirtualList
                className={cx(classes.tbody, classNames.tbody)}
                height={tbodyHeight}
                itemCount={data.length}
                itemSize={rowHeight}
                overscanCount={overscan}
                renderItem={renderRow}
            />
        </div>
    );
};

Grid.propTypes = {
    classes: PropTypes.object,
    classNames: PropTypes.shape({
        table: PropTypes.string,
        thead: PropTypes.string,
        row: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        tbody: PropTypes.string,
        th: PropTypes.string,
        td: PropTypes.string,
        thActions: PropTypes.string,
        tdActions: PropTypes.string,
    }),
    rowHeight: PropTypes.number.isRequired,
    theadHeight: PropTypes.number,
    data: PropTypes.array.isRequired,
    overscan: PropTypes.number,
    sortable: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    columnsWidths: PropTypes.object.isRequired,
    columnsNames: PropTypes.object,
    columnsAlignments: PropTypes.object,
    columnsFormatters: PropTypes.object,
    columnsClasses: PropTypes.object,
    columnsTooltips: PropTypes.object,
    defaultSorters: PropTypes.object,
    customSorter: PropTypes.func,
    rowPadding: PropTypes.number,
    columnPadding: PropTypes.number,
    onRowClick: PropTypes.func,
    onColumnClick: PropTypes.object,
    rowActionsRenderer: PropTypes.any,
};

export default Grid;
