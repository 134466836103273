import React, {useState, useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import axios from 'axios';
import * as styles from '../theme.js';
import Filterbar from './Filterbar.js';
import Grid from '../Grid.js';
import GridActions from './GridActions.js';
import ClinicalScore from "./ClinicalScore";
import FrailtyScore from "./FrailtyScore";
import Tooltip from '../Tooltip.js';
import AccountPopover from './AccountPopover.js';
import {sum} from 'lodash-es';
import {withRouter} from "react-router-dom";
import Spinner from "../Spinner";
import ReactGA from "react-ga";
import PropTypes from "prop-types";

const useStyles = createUseStyles({
    root: {
        display: 'grid',
        height: 'calc(100vh - 52px)',
        /*gridTemplateAreas: `
            "filterbar counters trendline"
            "filterbar grid grid"
        `,*/
        gridTemplateAreas: `
            "filterbar grid"
        `,
        gridTemplateColumns: '220px 1fr',
        gridGap: '22px',
        alignItems: 'start',
        paddingRight: 22,
    },

    filterbar: {
        gridArea: 'filterbar',
        height: '100%',
    },

    counters: {
        ...styles.paper,
        gridArea: 'counters',
        height: 'max-content',
        marginTop: 12,
        padding: 22,
        display: 'grid',
        gridTemplateRows: '1fr min-content 1fr',
        alignItems: 'center',

        '& hr': {
            width: '100%',
        },
    },

    counter: {
        display: 'grid',
        gridTemplateAreas: `"up img" "down img"`,
        gridTemplateColumns: '1fr min-content',

        '& > span': {
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            color: '#3e515e',
            textTransform: 'uppercase',
        },

        '& > strong': {
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 18,
            fontWeight: 'bold',
            color: '#253139',
        },

        '& > img': {
            gridArea: 'img',
            width: 50,
            height: 50,
            borderRadius: '50%',
        }
    },

    trendline: {
        ...styles.paper,
        gridArea: 'trendline',
        height: 'calc(100% - 12px)',
        marginTop: 12,
        padding: 22,

        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '20px 1fr',
        alignItems: 'center',
        gridRowGap: '22px',

        '& > select': {
            width: 'max-content',
        },

        '& > div': {
            height: '100%',
        }
    },

    patients: {
        ...styles.paper,
        height: "calc(100% - 168px)",
        overflow: "hidden",
        gridArea: "grid",
        marginTop: "64px",
    },

    employees: {
        ...styles.paper,
        height: "calc(100% - 168px)",
        gridArea: "grid",
        marginTop: "64px",
        overflow: 'hidden', // do not remove this! it's needed for availableHeight in <Grid />
    },

    buttons:{
        gridArea: 'grid',
        display: "grid",
        justifyContent: "center",
        gridTemplateColumns: "1fr 1fr",
        width: "400px",
        alignItems: "center",
        margin: "0 auto",
        height: "64px",
        gridColumnGap: "16px"
    },

    upperThead: {
        display: 'grid',
        gridTemplateColumns: 'min-content max-content 1fr',
        gridColumnGap: '24px',
        alignItems: 'center',
        height: 56,
        padding: [0, 22], // must be same of grid padding
        backgroundColor: '#e6f1f6',

        '& > span:nth-child(1)': {
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            fontWeight: 'bold',
            color: '#3e515e',
        },

        '& > span:nth-child(2)': {
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            color: '#8b949a',
        },

        '& > label': {
            marginBottom: 0, // tofix: wrong resetter
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            fontWeight: 'bold',
            color: '#3e515e',
            justifySelf: 'right',

            display: 'grid',
            alignItems: 'center',
            gridColumnGap: 6,
            gridTemplateColumns: 'repeat(2, max-content)'
        },
    },

    thead: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: 16,
        fontWeight: 'bold',
        color: '#3e515e',
        borderBottom: [1, 'solid', '#ccc'],
    },

    td: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: 14,
        color: '#3e515e',
        '&:first-child': {
            fontWeight: 'bold',
        },
    },
    tbody:{
        height: '100%!important'
    }
});

const Dashboard = ({location}) => {
    const classes = useStyles();

    // grid state
    const [patients, setPatients] = useState(undefined);
    const [employees, setEmployees] = useState(undefined);
    const [detailsShown, setDetailsShown] = useState(false);
    const [view, setView] = useState('patients');

    const toggleDetailsShown = () => setDetailsShown(!detailsShown);

    // form submit triggers fetch
    useEffect(() => {

        if (process.env.REACT_APP_APP_ENVIRONMENT === 'production') {
            ReactGA.initialize("UA-44803163-27");
            ReactGA.set({
                page: location.pathname,
            });
            ReactGA.pageview(location.pathname);
        }

        document.addEventListener('dashboard-search', fetchData);
        return () => document.removeEventListener('dashboard-search', fetchData);
    }, []);

    const fetchData = async ({detail: filters}) => {

            const {data:patients} = await axios.post(`${process.env.REACT_APP_ENDPOINT}/patients`, filters);
            const resPatients = patients.map(e => {
                e.fist_last_name = `${e.first_name} ${e.last_name}`;
                return e
            });
            setPatients(resPatients);

            const {data:employees} = await axios.post(`${process.env.REACT_APP_ENDPOINT}/employees`, filters);
            const resEmployees = employees.map(e => {
                e.fist_last_name = `${e.first_name} ${e.last_name}`;
                return e
            });
            setEmployees(resEmployees);

    };

    const onTabChange = (view) => {
        console.log("view setted,",view)
        setView(view);
        fetchData({});
    };

    const onChangePatientStatus = (fiscal_code) => async (event) => {
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow = `${tomorrow.getDate()}/${tomorrow.getMonth()+1}`;
        
        const value = event.target.value ? event.target.value : '';

        if (value !== `Rivalutare il ${tomorrow}` || window.confirm('Sicuro di voler cambiare lo stato paziente? Dopo questa azione, il paziente riceverà una mail di notifica che lo invita a ricompilare il questionario domani.')) {
            try {
                const response = await axios.post(`patient/${fiscal_code}/action`, {
                    text: value
                });
                response.map(e => {
                    if (e.fiscal_code === fiscal_code) {
                        e.action = value
                    }
                    return e;
                });
                setPatients(response)
            } catch (e) {

                alert('Abbiamo avuto un errore, ti preghiamo di riprovare o contattare il supporto.')
            }
        }
    };

    const onChangeEmployeeStatus = (id) => async (event) => {
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow = `${tomorrow.getDate()}/${tomorrow.getMonth()+1}`;

        const value = event.target.value ? event.target.value : '';

        if (value !== `Rivalutare il ${tomorrow}` || window.confirm('Sicuro di voler cambiare lo stato del dipendente? Dopo questa azione, il dipendente riceverà una mail di notifica che lo invita a ricompilare il questionario domani.')) {
            try {
                const response = await axios.post(`employee/${id}/action`, {
                    text: value
                });
                response.map(e => {
                    if (e.id === id) {
                        e.action = value
                    }
                    return e;
                });
                setEmployees(response);
            } catch (e) {
                alert('Abbiamo avuto un errore, ti preghiamo di riprovare o contattare il supporto.')
            }
        }
    };

    return (
        <main className={classes.root}>
            <Filterbar className={classes.filterbar} view={view}/>

            {/*<div className={classes.counters}>
                <div className={cx(classes.counter, classes.counterUp)}>
                    <span>Totale utenti</span>
                    <strong>{Number(24000).toLocaleString()}</strong>
                    <img src={'https://data2.unhcr.org/bundles/owpcommon/img/xplaceholder.png.pagespeed.ic.gciKGoRlhW.png'} />
                </div>

                <hr />

                <div className={cx(classes.counter, classes.counterDown)}>
                    <span>Totale utenti</span>
                    <strong>{Number(59000).toLocaleString()}</strong>
                    <img src={'https://data2.unhcr.org/bundles/owpcommon/img/xplaceholder.png.pagespeed.ic.gciKGoRlhW.png'} />
                </div>
            </div>

            <div className={classes.trendline}>
                <select defaultValue={'last_week'}>
                    <option value={'last_week'}>Ultima settimana</option>
                </select>

                <div className={classes.trendlineContainer}>
                    https://www.chartjs.org/docs/latest/charts/line.html
                    <Line
                        data={{
                            datasets: [{
                                label: 'Nuovi utenti',
                                data: [12, 20, 12, 40, 50],
                                borderColor: 'orange',
                            },{
                                label: 'Questionari giornalieri',
                                data: [7, 12, 26, 21, 39],
                                borderColor: 'lightblue',
                            }],
                            labels: ['January', 'February', 'March', 'April'],
                        }}
                        width={100}
                        height={50}
                        options={{
                            maintainAspectRatio: false,
                            legend: { display: false },
                            tooltips: { enabled: true },
                        }}
                    />
                </div>
            </div>*/}
            <div className={classes.buttons}>
                <button className="btn btn-default" onClick={() => onTabChange('patients')} disabled={view === 'patients'}>Pazienti</button>
                <button className="btn btn-default" onClick={() => onTabChange('employees')} disabled={view === 'employees'}>Dipendenti</button>
            </div>
            {view === 'patients' && <div className={classes.patients}>
                {patients === undefined && <Spinner center={true} overlay={true}/>}

                {patients !== undefined &&
                <div className={classes.grid}>
                    <div className={classes.upperThead}>
                        <span>Questionari</span>
                        <span>{sum(patients.map(patient => patient.answers_count || 0)).toLocaleString()} totali</span>
                    </div>
                    <Grid
                        id={'grid'}
                        data={patients}
                        updateData={setPatients}  // needed for sorting!
                        rowHeight={56}
                        theadHeight={56}
                        upperTheadHeight={56}
                        rowPadding={24}
                        columnPadding={8}
                        columns={['date', 'fist_last_name', 'age', 'severity_score', 'covid_score', 'patient_status', 'action']}
                        columnsNames={{
                            date: 'Data',
                            fist_last_name: 'Nome',
                            age: 'Età',
                            severity_score: 'Frailty score',
                            covid_score: 'Clinical score',
                            patient_status: 'Pre-anamnesi',
                            action: 'Nota'
                        }}
                        columnsWidths={{
                            date: 15,
                            fist_last_name: 15,
                            age: 5,
                            severity_score: 12,
                            covid_score: 12,
                            patient_status: 20,
                            action: 18
                        }}
                        classNames={{
                            thead: classes.thead,
                            td: classes.td,
                            tbody: classes.tbody,
                            thActions: classes.thActions,
                            tdActions: classes.tdActions,
                        }}
                        headerFormatters={{
                            severity_score: <Tooltip width={450} fontSize={12} marginY={-70}
                                                     content={`Frailty score: risultato ottenuto dalla somma dei punteggi inerenti alle comorbidità dei pazienti (età, fumatore, patologie pregresse, ecc.).`}>Frailty
                                ⓘ</Tooltip>,
                            covid_score: <Tooltip width={450} fontSize={12} marginY={-70}
                                                  content={`Clinical score: risultato ottenuto dalla somma dei punti per rilevanza clinica delle risposte date in anamnesi.`}>Clinical
                                ⓘ</Tooltip>,
                        }}
                        columnsFormatters={{
                            severity_score: (value, {severity_score}) => <FrailtyScore score={severity_score}/>,
                            covid_score: (value, {covid_score, progress}) => <ClinicalScore score={covid_score}
                                                                                            increment={progress}/>,
                        }}
                        columnsAlignments={{
                            severity_score: 'center',
                            covid_score: 'center',
                        }}
                        actionsLabel={''}
                        rowActionsRenderer={GridActions}
                        onRowClick={({fiscal_code}) => () => setDetailsShown(fiscal_code)}
                    />
                </div>
                }
                {!!detailsShown &&
                <AccountPopover fiscal_code={detailsShown} onChangeAction={onChangePatientStatus} onClose={toggleDetailsShown}/>}
            </div>}

            {view === 'employees' && <div className={classes.employees}>
                {employees === undefined && <Spinner center={true} overlay={true}/>}

                {employees !== undefined &&
                <div className={classes.grid}>
                    <div className={classes.upperThead}>
                        <span>Questionari</span>
                        <span>{sum(employees.map(patient => patient.answers_count || 0)).toLocaleString()} totali</span>
                    </div>
                    <Grid
                        id={'grid'}
                        data={employees}
                        updateData={setEmployees}  // needed for sorting!
                        rowHeight={56}
                        theadHeight={56}
                        upperTheadHeight={56}
                        rowPadding={24}
                        columnPadding={8}
                        columns={['date', 'fist_last_name', 'age', 'severity_score', 'covid_score', 'patient_status', 'action']}
                        columnsNames={{
                            date: 'Data',
                            fist_last_name: 'Nome',
                            age: 'Età',
                            severity_score: 'Frailty score',
                            covid_score: 'Clinical score',
                            patient_status: 'Pre-anamnesi',
                            action: 'Nota'
                        }}
                        columnsWidths={{
                            date: 15,
                            fist_last_name: 15,
                            age: 5,
                            severity_score: 12,
                            covid_score: 12,
                            patient_status: 20,
                            action: 18
                        }}
                        classNames={{
                            thead: classes.thead,
                            td: classes.td,
                            thActions: classes.thActions,
                            tdActions: classes.tdActions,
                        }}
                        headerFormatters={{
                            severity_score: <Tooltip width={450} fontSize={12} marginY={-70}
                                                     content={`Frailty score: risultato ottenuto dalla somma dei punteggi inerenti alle comorbidità dei pazienti (età, fumatore, patologie pregresse, ecc.).`}>Frailty
                                ⓘ</Tooltip>,
                            covid_score: <Tooltip width={450} fontSize={12} marginY={-70}
                                                  content={`Clinical score: risultato ottenuto dalla somma dei punti per rilevanza clinica delle risposte date in anamnesi.`}>Clinical
                                ⓘ</Tooltip>,
                        }}
                        columnsFormatters={{
                            severity_score: (value, {severity_score}) => <FrailtyScore score={severity_score}/>,
                            covid_score: (value, {covid_score, progress}) => <ClinicalScore score={covid_score}
                                                                                            increment={progress}/>,
                            patient_status: (value) => +value === 0 ? <span>Non ha sintomi</span> : <span>{value}</span>
                        }}
                        columnsAlignments={{
                            severity_score: 'center',
                            covid_score: 'center',
                        }}
                        actionsLabel={''}
                        rowActionsRenderer={GridActions}
                        onRowClick={({id}) => () => setDetailsShown(id)}
                    />
                </div>
                }
                {!!detailsShown &&
                <AccountPopover id={detailsShown} onChangeAction={onChangeEmployeeStatus} onClose={toggleDetailsShown}/>}
            </div>}
        </main>
    );
};

export default withRouter(Dashboard);
