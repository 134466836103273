import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import * as styles from '../theme.js';
import {NavLink, useLocation, withRouter} from "react-router-dom";
import ReactGA from "react-ga";
import Filterbar from "../Dashboard/Filterbar";
import BusinessUsersEmails from "../../Components/BusinessUsersEmails/BusinessUsersEmails";
import {useDispatch, useSelector} from 'react-redux'
import {toMoney} from "../../Helpers/common";
import {httpActivateAutoRenew, httpDeactivateAutoRenew, httpGetUserMe} from "../../http/user.http";
import * as usersActionCreators from "../../store/actions/usersActions";
import {updateUser} from "../../store/actions/usersActions";

const useStyles = createUseStyles({
    root: {
        display: 'grid',
        height: 'calc(100vh - 52px)',
        /*gridTemplateAreas: `
            "filterbar counters trendline"
            "filterbar grid grid"
        `,*/
        gridTemplateAreas: `
            "filterbar grid"
        `,
        gridTemplateColumns: '220px 1fr',
        gridGap: '22px',
        alignItems: 'start',
        paddingRight: 22,
    },

    filterbar: {
        gridArea: 'filterbar',
        height: '100%',
    },

    counters: {
        ...styles.paper,
        gridArea: 'counters',
        height: 'max-content',
        marginTop: 12,
        padding: 22,
        display: 'grid',
        gridTemplateRows: '1fr min-content 1fr',
        alignItems: 'center',

        '& hr': {
            width: '100%',
        },
    },

    counter: {
        display: 'grid',
        gridTemplateAreas: `"up img" "down img"`,
        gridTemplateColumns: '1fr min-content',

        '& > span': {
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            color: '#3e515e',
            textTransform: 'uppercase',
        },

        '& > strong': {
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 18,
            fontWeight: 'bold',
            color: '#253139',
        },

        '& > img': {
            gridArea: 'img',
            width: 50,
            height: 50,
            borderRadius: '50%',
        }
    },

    trendline: {
        ...styles.paper,
        gridArea: 'trendline',
        height: 'calc(100% - 12px)',
        marginTop: 12,
        padding: 22,

        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '20px 1fr',
        alignItems: 'center',
        gridRowGap: '22px',

        '& > select': {
            width: 'max-content',
        },

        '& > div': {
            height: '100%',
        }
    },

    grid: {
        /*...styles.paper,*/
        gridArea: 'grid',
        marginTop: 16,
        height: 'calc(100% - 32px)',
        // height: 'fit-content', // table automatically set correct borders if few rows
        overflow: 'hidden', // do not remove this! it's needed for availableHeight in <Grid />
    },

    upperThead: {
        display: 'grid',
        gridTemplateColumns: 'min-content max-content 1fr',
        gridColumnGap: '24px',
        alignItems: 'center',
        height: 56,
        padding: [0, 22], // must be same of grid padding
        backgroundColor: '#e6f1f6',

        '& > span:nth-child(1)': {
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            fontWeight: 'bold',
            color: '#3e515e',
        },

        '& > span:nth-child(2)': {
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            color: '#8b949a',
        },

        '& > label': {
            marginBottom: 0, // tofix: wrong resetter
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            fontWeight: 'bold',
            color: '#3e515e',
            justifySelf: 'right',

            display: 'grid',
            alignItems: 'center',
            gridColumnGap: 6,
            gridTemplateColumns: 'repeat(2, max-content)'
        },
    },

    thead: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: 16,
        fontWeight: 'bold',
        color: '#3e515e',
        borderBottom: [1, 'solid', '#ccc'],
    },

    td: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: 14,
        color: '#3e515e',
        '&:first-child': {
            fontWeight: 'bold',
        },
    },
    tables: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: '32px'
    },
    button: {
        ...styles.button,
        textAlign: 'center',
        '&:hover': {
            textDecoration: 'none',
            color: '#fff'
        }
    },
    buttons: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(100px, 220px))",
        gridColumnGap: "16px"
    },
    infos: {
        display: 'grid',
        gridColumnGap: 16,
        gridTemplateColumns: '1fr 1fr',
        padding: '20px 0',
        gridAutoRows: 'min-content',
    },
    card: {
        ...styles.paper,
        display: 'grid',
        gridColumnGap: 16,
        justifyItems: 'center',
        padding: '20px 0',
    },
    danger:{

    }
});

const BusinessDash = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const currentUser = useSelector(state => state.users.currentUser);
    // grid state
    const [data, setData] = useState(undefined);
    /*const [detailsShown, setDetailsShown] = useState(false);
    const toggleDetailsShown = () => setDetailsShown(!detailsShown);*/

    // form submit triggers fetch
    useEffect(() => {
        if (process.env.REACT_APP_APP_ENVIRONMENT === 'production') {
            ReactGA.initialize("UA-44803163-27");
            ReactGA.set({
                page: location.pathname,
            });
            ReactGA.pageview(location.pathname);
        }

        /*document.addEventListener('dashboard-search', fetchData);
        return () => document.removeEventListener('dashboard-search', fetchData);*/
    }, []);

    const enableRenewToggle = async() => {
        try{
            if(!!currentUser.autorenew_subscription){
                if (!window.confirm(`Sei sicuro di voler disattivare il rinnovo?`))
                    return;
                const {data:response} = await httpDeactivateAutoRenew();
            }else{
                if (!window.confirm(`Sei sicuro di voler attivare il rinnovo?`))
                    return;
                const {data:response} = await httpActivateAutoRenew();
            }
            await userMe();
        }catch (e) {
            if(e.response.data.message){
                window.alert(e.response.data.message);
            }else{
                window.alert('Si è verificato un errore.');
            }
        }
    };

    const userMe = async () => {
        const {data: user} = await httpGetUserMe();
        const token = localStorage.getItem('token');
        if (token) {
            dispatch(updateUser(user))
        }
    };

    return (
        <main className={classes.root}>
            <Filterbar className={classes.filterbar} businessFilter={true}/>
            <div className={classes.grid}>
                <div className={classes.buttons}>
                    <NavLink className={classes.button} to={"/business-order"}> Crea un nuovo ordine </NavLink>
                    {!!currentUser.has_his_own_doctor &&
                    <NavLink className={classes.button} to={"/add-business-doctor"}> Aggiungi medico </NavLink>}
                </div>
                <div className={classes.tables}>
                    <BusinessUsersEmails/>
                    {(+currentUser.current_renew_price > 0) && <div className={classes.infos}>
                        <div className={classes.card}>
                            <p>Prezzo prossimo rinnovo</p>
                            <h3>{toMoney(currentUser.current_renew_price)}</h3>
                            <button className={classes.button} style={{backgroundColor: !!currentUser.autorenew_subscription ? 'red' : 'green'}} onClick={() => enableRenewToggle()}>
                                {(!!currentUser.autorenew_subscription) ? "Blocca rinnovo" : "Attiva rinnovo"}
                            </button>
                        </div>
                    </div>}
                </div>
            </div>
        </main>
    );
};


export default BusinessDash;
