import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import * as Yup from 'yup';
import classes from './PasswordReset.module.scss';
import {Field, Formik} from "formik";
import {httpResetPasswordRequest} from "../../../http/user.http";
import ProcessCompleted from "../../../Components/Shared/ProcessCompleted/ProcessCompleted.component";
import Back from "../../../Components/Shared/Back/Back.component";


const ResetSchema = Yup.object().shape({
	email: Yup.string()
		.email('Email non valida.')
		.required('Email richiesta.'),
});


class PasswordReset extends Component {

	static propTypes = {
		currentUser: PropTypes.object,
		setLoggedUser: PropTypes.func
	};

	state = {
		passwordReset: {
			email: ""
		},
		success: false,
	};

	componentDidMount = () => {
		const {currentUser, history} = this.props;
		if (currentUser) {
			history.push("/");
		}
	};

	componentWillUnmount = () => {
	};

	render = () => {
		const {handleSubmit} = this;
		const {passwordReset, success} = this.state;

		return <section className={`${classes.fullPage}`}>
            <div className={`container ${classes.fullHeight}`}>
                {success ?
                    <div className={`row align-items-center ${classes.fullHeight}`}>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                            <ProcessCompleted title={`Link inviato`} message={`Ti abbiamo inviato un link all'email indicata.`}
                                              submessage={`Clicca sul link per cambiare la tua password`}
                                              to={`/login`} btnText={`Torna al login`}/>
                        </div>
                    </div>
                    :
                    <div className={`row align-items-center ${classes.fullHeight}`}>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                            <div className={`${classes.backButtonWrapper}`}>
                                <Back to={"/"}/>
                            </div>
                            <Formik
                                initialValues={passwordReset}
                                validationSchema={ResetSchema}
                                onSubmit={(values, {setSubmitting, setErrors}) => {
                                    handleSubmit(values, setErrors, setSubmitting);
                                }}
                            >
                                {({
                                      errors,
                                      touched,
                                      handleSubmit,
                                      isSubmitting,
                                      isValid
                                  }) => (
                                    <form onSubmit={handleSubmit} className={``}>
                                        <h2 className={classes.title}>Hai dimenticato la password?</h2>
                                        <p className={classes.subtitle}>Inserisci il tuo indirizzo email. Riceverai una mail
                                            contenente un link per creare una nuova password.</p>
                                        <div className={`form-group ${classes.fieldWrap}`}>
                                            <label htmlFor={'email'}>Email</label>
                                            <Field name='email' type='email'
                                                   className={(errors.email && touched.email) ? 'is-invalid' : null}/>
                                            {errors.email && touched.email &&
                                            <div className='invalid-feedback'>{errors.email}</div>}
                                            {errors.message &&
                                            <div className='invalid-feedback'
                                                 style={{display: "block"}}>{errors.message}</div>}
                                        </div>
                                        <div className="form-group">
                                            <button className={`btn btn-default`} type="submit" disabled={!isValid || isSubmitting}>
                                                Richiedi
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>}
            </div>
        </section>
	};

	handleSubmit = async (values, setErrors, setSubmitting) => {
		setSubmitting(true);
		try {
			await httpResetPasswordRequest(values);
			this.setState({
				success: true,
			});
			setSubmitting(false)
		} catch (e) {
			setErrors(e.response.data);
			this.setState({
				success: false,
			});
			setSubmitting(false);
		}
	};
}

export default withRouter(PasswordReset);
