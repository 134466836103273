import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import * as Yup from 'yup';
import classes from './PasswordResetConfirm.module.scss'
import {Field, Formik} from "formik";
import {httpResetPasswordConfirmation, httpResetPasswordPermission} from "../../../http/user.http";
import ProcessCompleted from "../../../Components/Shared/ProcessCompleted/ProcessCompleted.component";
import Back from "../../../Components/Shared/Back/Back.component";

const ResetSchema = Yup.object().shape({
    password1: Yup.string()
        .required('Password richiesta.')
        .min(8, 'La password è troppo corta.'),
    password2: Yup.string()
        .oneOf([Yup.ref('password1')], 'Le password devono corrispondere.')
        .required('Conferma la password per continuare.'),
});

class PasswordResetConfirm extends Component {

    static propTypes = {
        currentUser: PropTypes.object,
        setLoggedUser: PropTypes.func
    };

    state = {
        passwordResetConfirm: {
            password1: "",
            password2: ""
        },
        success: false,
        wrongToken: false,
        token: null,
        email: "",
        userName: null,
        loading: true
    };

    componentDidMount = () => {
        this.readParams();
    };

    componentWillUnmount = () => {
    };

    render = () => {
        const {handleSubmit} = this;
        const {passwordResetConfirm, success, wrongToken, userName,loading} = this.state;

        return <section className={`${classes.fullPage}`}>
                    <div className={`container ${classes.fullHeight}`}>
                    {wrongToken ?
                        <div className={`row align-items-center ${classes.fullHeight}`}>
                            <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                                <div className={`${classes.backButtonWrapper}`}>
                                    <Back to={"/"}/>
                                </div>
                                <ProcessCompleted title={`Link scaduto`}
                                                  message={`Il link che stai utilizzando non è valido.`}
                                                  btnText={`Login`}
                                                  to={"/login"}/>
                            </div>
                        </div>
                        : success ?
                            <div className={`row align-items-center ${classes.fullHeight}`}>
                                <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                                    <div className={`${classes.backButtonWrapper}`}>
                                        <Back to={"/"}/>
                                    </div>
                                    <ProcessCompleted title={`Nuova password inserita`}
                                                      message={`La password è stata reimpostata correttamente`}
                                                      to={"/login"}
                                                      btnText={`Login`}/>
                                </div>
                            </div>
                            : !loading && <div className={`row align-items-center ${classes.fullHeight}`}>
                                <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                                    <div className={`${classes.backButtonWrapper}`}>
                                        <Back to={"/"}/>
                                    </div>
                                    <Formik
                                        initialValues={passwordResetConfirm}
                                        validationSchema={ResetSchema}
                                        onSubmit={(values, {setSubmitting, setErrors}) => {
                                            handleSubmit(values, setErrors);
                                            setSubmitting(false);
                                        }}
                                    >
                                        {({
                                              values,
                                              errors,
                                              touched,
                                              handleChange,
                                              handleBlur,
                                              handleSubmit,
                                              isSubmitting,
                                              isValid
                                          }) => (
                                            <form onSubmit={handleSubmit} className={``}>
                                                <h2 className={`${classes.title}`}>
                                                    Inserisci la nuova password
                                                </h2>
                                                <p className={`${classes.subtitle}`}>La password deve avere minimo 8
                                                    caratteri. Per renderla più sicura, utilizza almeno una maiuscola,
                                                    un numero e un carattere speciale.</p>
                                                <div className={`form-group ${classes.fieldWrap}`}>
                                                    <label htmlFor={'password1'}>Password</label>
                                                    <Field name='password1' type='password'
                                                           className={(errors.password1 && touched.password1) ? 'is-invalid' : null}/>
                                                    {errors.password1 && touched.password1 &&
                                                    <div className='invalid-feedback'>{errors.password1}</div>}
                                                </div>
                                                <div className={`form-group ${classes.fieldWrap}`}>
                                                    <label htmlFor={'password2'}>Conferma password</label>
                                                    <Field name='password2' type='password'
                                                           className={(errors.password2 && touched.password2) ? 'is-invalid' : null}/>
                                                    {errors.password2 && touched.password2 &&
                                                    <div className='invalid-feedback'>{errors.password2}</div>}
                                                </div>
                                                <div className="form-group">
                                                    <button className={`btn btn-default`} type="submit" disabled={!isValid || isSubmitting}>
                                                        Conferma
                                                    </button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                    }
                </div>
        </section>
    };

    readParams = async () => {
        const token = this.props.match.params.token;
        try {
            const {data: response} = await httpResetPasswordPermission(token);

            this.setState({
                wrongToken: false,
                email: response.email,
                token: response.token,
                loading: false
            })
        } catch (e) {
            this.setState({
                wrongToken: e.response.data,
                loading: false
            })
        }
    };

    handleSubmit = async (values, setErrors) => {
        const {email, token} = this.state;
        const data = {
            email: email,
            password: values.password1,
            password_confirmation: values.password2,
            token: token
        };
        try {
            const {data: response} = await httpResetPasswordConfirmation(data);
            this.setState({
                success: true,
                userName: response.first_name
            })
        } catch (e) {
            setErrors(e.response.data);
            this.setState({
                success: false,
                userName: null
            })
        }
    };
}

export default withRouter(PasswordResetConfirm);
