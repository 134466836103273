import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import * as styles from '../theme.js';
import {useParams} from 'react-router';
import {useFieldArray, useForm} from 'react-hook-form';
import Spinner from "../Spinner.js";
import axios from "axios";
import {FaTrash} from "react-icons/fa";
import {useSelector} from 'react-redux'
import {NavLink} from "react-router-dom";

const useStyles = createUseStyles({
    root: {
        position: 'relative',
        margin: [40, 0],
    },

    title: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: 20,
        fontWeight: 'bold',
        color: '#3e515e',
        textAlign: 'center',
        padding: 30,
        marginBottom: 38,
        borderBottom: '1px solid #e4e7eb',
    },

    form: {
        ...styles.paper,
        width: 500,
        margin: [0, 'auto'],
        padding: '24px',
        '& hr': {
            width: 'calc(100% + 46px)',
            marginLeft: -24,
            marginTop: 0, //tofix: resetter
            marginBottom: 0, //tofix: resetter
            gridColumn: '1 / span 2',
        },
        '& textarea': {
            cursor: "auto",
            width: "100%",
            padding: "8px"
        },

        '& svg': {
            width: 20,
            fill: '#3e515e',
        },

        '& label': {
            height: 'max-content',
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 16,
            color: '#3e515e',
            marginBottom: 6,
            // in case label has multiple elements inside
            display: 'grid',
            gridAutoFlow: 'column',
            gridAutoColumns: 'max-content',
            gridColumnGap: '10px',
        },

        '& label[data-inline]': {
            height: 'max-content',
            display: 'grid',
            gridAutoFlow: 'column',
            gridAutoColumns: 'auto',
            alignItems: 'center',
            justifyContent: 'left',
            gridColumnGap: '14px',
            marginBottom: 4,
        },

        '& label > span': {
            fontFamily: "'Titillium Web', sans-serif",
            fontWeight: 'normal',
        },

        '& ul': {
            listStyle: 'none',
            padding: '0',
            '& li': {
                display: "grid",
                gridTemplateColumns: "1fr auto",
                alignItems: "end",
                gridColumnGap: "16px",
                margin: '0  0 24px 0',
                '& input':{
                    width: '100%!important',
                },
                '& button':{
                    backgroundColor: "red",
                    '&:disabled':{
                        backgroundColor: "#ccc",
                        cursor: 'not-allowed'
                    },
                    '& svg':{
                        fill: '#fff'
                    }
                },
            }
        },

        '& button': styles.button,
    },

    /*buttons: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'max-content max-content 1fr',
        gridColumnGap: '20px',
        gridColumn: '1 / span 2',
        gridAutoFlow: 'column',

        '& > button': {
            justifySelf: 'right',
        },
    },*/

    error: {
        color: 'red',
        display: 'grid',
        marginTop: 8
    },

    recap: {
        ...styles.paper,
        width: "500px",
        margin: "0 auto",
        display: "grid",
        height: "auto",
        padding: "24px",
        '& hr': {
            width: '100%',
        },
        '& h4': {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '8px 0',
            '& span': {
                fontSize: '14px'
            },
            '& total': {
                fontSize: '20px',
                color: 'green'
            }
        }
    },

    card: {
        ...styles.paper,
        width: 500,
        margin: [0, 'auto'],
        padding: '24px',
    }
});

const BusinessAddDoctor = ({history}) => {
    const classes = useStyles();
    const doctor_codes = useSelector(state => state.users.currentUser.doctors_codes);

    const {register, handleSubmit, setError, errors, reset, control, getValues} = useForm({
        mode: 'onBlur',
        validateCriteriaMode: 'all',
        nativeValidation: false, // in order to use datalist with multiple values 1) type=email with native validation disabled 2) multiple=true
        defaultValues: process.env.NODE_ENV === 'development' ? {
            doctors_codes: doctor_codes ? doctor_codes : [""]
        } : undefined,
    });
    const {fields, append, remove} = useFieldArray({
        control,
        name: "doctors_codes"
    });
    // loading
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {

    }, []);

    const onSubmit = async values => {
        console.log(values.doctors_codes)
        try {
            setLoading(true);
            const {data} = await axios.post('/add-doctors-codes', values);
            console.log(data);
            setSuccess(true);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                Object.keys(error.response.data.error).map(e => {
                    setError(e, 'error', error.response.data.error[e])
                })
            } else {
                window.alert('Si è verificato un errore.');
            }
        } finally {
            setLoading(false);
        }
    };

    return <div className={classes.root}>
        {loading && <Spinner center={true} overlay={true}/>}

        {!success && <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate={true}>
            <div>
                <h2 className={classes.title}>Inserimento nuovi dottori</h2>
                <p className={classes.subtitle}>
                    Inserisci di seguito i codici medico dei dottori che desideri aggiungere.
                </p>
            </div>
            <ul className={classes.control}>
                {fields.map((item, index) => (
                    <li key={item.id}>
                        <div>
                            <label>Codice Medico #{index + 1}</label>
                            <input name={`doctors_codes[${index}]`}
                                   type='text'
                                   required={true}
                                   placeholder="codice medico"
                                   ref={register}/>
                        </div>
                        <button type={'button'} disabled={fields.length < 2} onClick={() => remove(index)}><FaTrash/></button>
                    </li>
                ))}
                <button type="button" onClick={() => append()}>
                    Aggiungi
                </button>
            </ul>

            <div className={classes.buttons}>
                <button type={'submit'}>Salva</button>
            </div>
        </form>}

        {success && <div className={classes.card}>
            <h2 className={classes.title}>Codici modificati con successo</h2>
            <p className={classes.subtitle}>
               I medici saranno presto collegati.
            </p>
            <NavLink to={"/business-dash"}>Torna alla dashboard</NavLink>
        </div>}
    </div>

};

export default BusinessAddDoctor;
