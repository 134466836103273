import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

const useStyles = createUseStyles({
    root: {
        height: ({ size }) => size,
        width: ({ size }) => size,
        'enable-background': 'new 0 0 50 50',
        fill: ({ fill }) => fill,
        zIndex: 2,
    },

    center: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },

    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        zIndex: 1,
    },
});

const Spinner = ({ className, center = false, size = 40, fill = '#666', overlay = false, ...otherProps }) => {
    const classes = useStyles({ size, fill });

    return (
        <>
        { overlay && <div className={classes.overlay}/> }
        <svg className={cx(classes.root, center && classes.center, className)} {...otherProps} x={0} y={0} width={size} height={size} viewBox={'0 0 50 50'} xmlSpace={'preserve'}>
            <path d={'M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z'}>
                <animateTransform attributeType={'xml'} attributeName={'transform'} type={'rotate'} from={'0 25 25'} to={'360 25 25'} dur={'0.6s'} repeatCount={'indefinite'} />
            </path>
        </svg>
        </>
    );
};

Spinner.propTypes = {
    className: PropTypes.string,
    center: PropTypes.bool,
    size: PropTypes.number,
    fill: PropTypes.string,
    overlay: PropTypes.bool,
};

export default Spinner;
