import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

const useStyles = createUseStyles(theme => ({
    root: {
        'width': 'max-content',
        'display': 'grid',
        'grid-auto-flow': 'row',
    },

    label: {
        'font-size': 12,
        'font-weight': 'bold',
        'text-transform': 'uppercase',
        'color': '#888',
        'marginBottom': 5,
    },

    input: {
        width: 30,
        height: 'auto',

        '&[type=checkbox]:not(:checked)': {
            content: ({ colorUnchecked = '#888', colorErrored, errors }) => `url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='${encodeURIComponent(errors ? colorErrored: colorUnchecked)}' d='M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z'/%3e%3c/svg%3e")`,
        },

        '&[type=checkbox]:checked': {
            content: ({ colorChecked = '#888', colorErrored, errors }) => `url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='${encodeURIComponent(errors ? colorErrored: colorChecked)}' d='M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M19,5V19H5V5H19M10,17L6,13L7.41,11.58L10,14.17L16.59,7.58L18,9'/%3e%3c/svg%3e")`,
        },

        '&[type=radio]:not(:checked)': {
            content: `url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'/%3e%3c/svg%3e")`,
        },

        '&[type=radio]:checked': {
            content: `url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4M11,16.5L6.5,12L7.91,10.59L11,13.67L16.59,8.09L18,9.5L11,16.5Z'/%3e%3c/svg%3e")`,
        },
    },
}));

const Checkbox = forwardRef(({ className, label, helper, colorUnchecked, colorChecked, colorErrored = 'red', errors, ...props }, ref) => {
    const classes = useStyles({ colorUnchecked, colorChecked, colorErrored, errors: !!errors });

    return (
        <label id={props.name} className={cx(classes.root, className)}>
            { !!label && <span className={classes.label}>{label}</span> }
            <input type={'checkbox'} className={classes.input} ref={ref} {...props} />
            { !!helper && <span className={classes.helpers}>{helper}</span> }
            { !!errors && <small className={classes.error}>{errors.message}</small> }
        </label>
    );
});

export default Checkbox;
