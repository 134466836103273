import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import Grid from '../Grid.js';
import {useForm} from 'react-hook-form'
import {connect} from "react-redux";
import * as styles from '../theme.js';
import {EditIcon} from "../icons";
import {NavLink, withRouter} from "react-router-dom";
import * as actionCreators from "../../store/actions/usersActions";
import axios from 'axios';
import ActivityLabel from "./ActivityLabel";
import ReactGA from "react-ga";

const useStyles = createUseStyles({
    root: {
        display: 'grid',
        height: 'calc(100vh - 52px)',
        gridTemplateAreas: `
            "title"
            "grid"
        `,
        // "filterbar footer footer"
        gridTemplateColumns: '1fr',
        gridTemplateRows: '80px 1fr', // add 60px for footer
        gridGap: '22px',
        padding: 22,
    },

    title: {
        ...styles.paper,
        gridArea: 'title',
        // height: 'fit-content', // table automatically set correct borders if few rows
        overflow: 'hidden', // do not remove this! it's needed for availableHeight in <Grid />
        padding: 16
    },
    searchForm: {
        display: 'grid',
        gridTemplateColumns: '330px 110px auto 160px',
        gridColumnGap: 16,
        alignItems: 'center'
    },

    grid: {
        ...styles.paper,
        gridArea: 'grid',
        height: 'calc(100% - 12px)',
        // height: 'fit-content', // table automatically set correct borders if few rows
        overflow: 'hidden', // do not remove this! it's needed for availableHeight in <Grid />
    },


    upperThead: {
        display: 'grid',
        gridTemplateColumns: 'max-content max-content 1fr',
        gridColumnGap: '24px',
        alignItems: 'center',
        height: 56,
        padding: [0, 22], // must be same of grid padding
        backgroundColor: '#e6f1f6',

        '& > span:nth-child(1)': {
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            fontWeight: 'bold',
            color: '#3e515e',
        },

        '& > span:nth-child(2)': {
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            color: '#8b949a',
        },

        '& > label': {
            marginBottom: 0, // tofix: wrong resetter
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            fontWeight: 'bold',
            color: '#3e515e',
            justifySelf: 'right',

            display: 'grid',
            alignItems: 'center',
            gridColumnGap: 6,
            gridTemplateColumns: 'repeat(2, max-content)'
        },
    },

    thead: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: 16,
        fontWeight: 'bold',
        color: '#3e515e',
        borderBottom: [1, 'solid', '#ccc'],
    },

    td: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: 14,
        color: '#3e515e',
        '&:first-child': {
            fontWeight: 'bold',
        },
    },

    thActions: {
        flexBasis: '10%',
    },

    tdActions: {
        flexBasis: '10%',
    },

    statusColumn: {
        color: '#8b949a',
    },

    scoreColumn: {
        height: '100%',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'max-content max-content',
        gridGap: '10px',
        fontWeight: 'bold',
        '& > svg': {
            width: 12,
        },
    },
    btn: {
        maxWidth: 140,
        height: 40,
        borderRadius: 6,
        padding: 8,
        boxShadow: '0 2px 4px 0 rgba(62, 81, 94, 0.32)',
        backgroundColor: '#2092c1',
        borderColor: '#2092c1',
        color: '#fff',
        '&:hover:not(:disabled)': {
            backgroundColor: '#2083b1',
            borderColor: '#2092c1',
            color: '#fff',
            transition: 'ease-in 0.2s',
        },
        '&:disabled': {
            cursor: 'not-allowed',
        },
        '&:not(:disabled)': {
            '&:not(.disabled)': {
                '&:active': {
                    backgroundColor: '#2083b1',
                    borderColor: '#2092c1',
                    color: '#fff'
                }
            }
        }
    }
});

const AccountList = ({location}) => {
    const classes = useStyles();
    const {register, handleSubmit} = useForm();

    // grid state
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filters, setFiltersData] = useState({name: '', active: -1});

    // grid state fetch
    useEffect(() => {
        if (process.env.REACT_APP_APP_ENVIRONMENT === 'production') {
            ReactGA.initialize("UA-44803163-27");
            ReactGA.set({
                page: location.pathname,
            });
            ReactGA.pageview(location.pathname);
        }
        (async () => {
            const data = await fetchData();
            setData(data);
            setFilteredData(data);
            setLoading(false)
        })();
    }, []);

    useEffect(() => {
        setLoading(true)
        const searchResult = data.filter(({first_name, last_name, email, active}) => (
            `${first_name} ${last_name} ${email}`.toLowerCase().includes(filters.name.toLowerCase()) &&
            (filters.active === -1 || filters.active === active)
        ));
        setFilteredData(searchResult);
        setLoading(false)
    }, [filters]);

    const onSubmit = ({name, active}) => {
        setFiltersData({
            ...filters,
            active: parseInt(active),
            name: name.toLowerCase()
        })
    };

    return (
        <main className={classes.root}>

            {loading &&
            <p>loading...</p>
            }
            <div className={classes.title}>
                <form className={classes.searchForm} onSubmit={handleSubmit(onSubmit)}>
                    <input type="text" name={'name'} ref={register} onChange={handleSubmit(onSubmit)}
                           placeholder={'Nome e Cognome o Email'}/>
                    <div>
                        {/*<label htmlFor="active">Filtro per stato</label>*/}
                        <select id={'active'} name="active" style={{height: 26}} ref={register}
                                onChange={handleSubmit(onSubmit)}>
                            <option value="-1">Tutti</option>
                            <option value="1">Attivi</option>
                            <option value="0">Disattivi</option>
                        </select>
                    </div>
                    <div style={{textAlign: 'right'}}>
                        <NavLink className={classes.btn} to={'/business-registration'}>+ Aggiungi business</NavLink>
                    </div>
                    <div style={{textAlign: 'right'}}>
                        <NavLink className={classes.btn} to={'/account'}>+ Aggiungi dottore</NavLink>
                    </div>
                </form>
            </div>
            {!loading &&
            <div className={classes.grid}>
                <div className={classes.upperThead}>
                    <span>Utenti Registrati</span>
                    <span>{data.length} totali</span>
                </div>
                <Grid
                    id={'grid'}
                    data={filteredData}
                    updateData={setFilteredData} // needed for sorting!
                    rowHeight={56}
                    theadHeight={56}
                    upperTheadHeight={56}
                    maxRows={10}
                    rowPadding={24}
                    columnPadding={24}
                    columns={['email', 'type', 'active', 'created_at']}
                    columnsNames={{
                        email: 'Email',
                        type: 'Tipo',
                        active: 'Stato',
                        created_at: 'Data creazione'
                    }}
                    columnsWidths={{
                        email: 30,
                        type: 30,
                        active: 10,
                        created_at: 30
                    }}
                    classNames={{
                        thead: classes.thead,
                        td: classes.td,
                        thActions: classes.thActions,
                        tdActions: classes.tdActions,
                    }}
                    columnsFormatters={{
                        first_name: (first_name, {last_name}) => `${first_name} ${last_name}`,
                        created_at: value => `${new Date(value).toLocaleDateString()}`,
                        active: value => <ActivityLabel score={value}/>,
                    }}
                    columnsClasses={{
                        score: () => classes.scoreColumn,
                        status: () => classes.statusColumn,
                    }}
                    actionsLabel={''}
                    rowActionsRenderer={(row) => <NavLink
                        to={row.type === 'doctor' ? `/account/${row.id}` : `/account-business/${row.id}`}
                        style={{textAlign: 'right'}}>
                        <EditIcon style={{maxWidth: '24px'}} fill={'rgb(139, 148, 154)'}/>
                    </NavLink>}
                />
            </div>
            }
            {/*<div className={classes.footer}>© 2020 Acme. All right reserved.</div>*/}
        </main>
    );
};

AccountList.propTypes = {};

const fetchData = async () => {
    const {data} = await axios.get('users');
    return data;
};

const mapStateToProps = state => {
    return {
        currentUser: state.users.currentUser
    }
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actionCreators.logout())
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)((AccountList)));
