import React from 'react';

export const SearchIcon = (props = {}) => <svg viewBox="0 0 24 24" {...props}>
    <path
        d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"/>
</svg>

export const GearIcon = (props = {}) => <svg viewBox="0 0 24 24" {...props}>
    <path
        d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M12,10A2,2 0 0,0 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12A2,2 0 0,0 12,10M10,22C9.75,22 9.54,21.82 9.5,21.58L9.13,18.93C8.5,18.68 7.96,18.34 7.44,17.94L4.95,18.95C4.73,19.03 4.46,18.95 4.34,18.73L2.34,15.27C2.21,15.05 2.27,14.78 2.46,14.63L4.57,12.97L4.5,12L4.57,11L2.46,9.37C2.27,9.22 2.21,8.95 2.34,8.73L4.34,5.27C4.46,5.05 4.73,4.96 4.95,5.05L7.44,6.05C7.96,5.66 8.5,5.32 9.13,5.07L9.5,2.42C9.54,2.18 9.75,2 10,2H14C14.25,2 14.46,2.18 14.5,2.42L14.87,5.07C15.5,5.32 16.04,5.66 16.56,6.05L19.05,5.05C19.27,4.96 19.54,5.05 19.66,5.27L21.66,8.73C21.79,8.95 21.73,9.22 21.54,9.37L19.43,11L19.5,12L19.43,13L21.54,14.63C21.73,14.78 21.79,15.05 21.66,15.27L19.66,18.73C19.54,18.95 19.27,19.04 19.05,18.95L16.56,17.95C16.04,18.34 15.5,18.68 14.87,18.93L14.5,21.58C14.46,21.82 14.25,22 14,22H10M11.25,4L10.88,6.61C9.68,6.86 8.62,7.5 7.85,8.39L5.44,7.35L4.69,8.65L6.8,10.2C6.4,11.37 6.4,12.64 6.8,13.8L4.68,15.36L5.43,16.66L7.86,15.62C8.63,16.5 9.68,17.14 10.87,17.38L11.24,20H12.76L13.13,17.39C14.32,17.14 15.37,16.5 16.14,15.62L18.57,16.66L19.32,15.36L17.2,13.81C17.6,12.64 17.6,11.37 17.2,10.2L19.31,8.65L18.56,7.35L16.15,8.39C15.38,7.5 14.32,6.86 13.12,6.62L12.75,4H11.25Z"/>
</svg>;

export const PhoneIcon = (props = {}) => <svg viewBox="0 0 24 24" {...props}>
    <path
        d="M20,15.5C18.8,15.5 17.5,15.3 16.4,14.9C16.3,14.9 16.2,14.9 16.1,14.9C15.8,14.9 15.6,15 15.4,15.2L13.2,17.4C10.4,15.9 8,13.6 6.6,10.8L8.8,8.6C9.1,8.3 9.2,7.9 9,7.6C8.7,6.5 8.5,5.2 8.5,4C8.5,3.5 8,3 7.5,3H4C3.5,3 3,3.5 3,4C3,13.4 10.6,21 20,21C20.5,21 21,20.5 21,20V16.5C21,16 20.5,15.5 20,15.5M5,5H6.5C6.6,5.9 6.8,6.8 7,7.6L5.8,8.8C5.4,7.6 5.1,6.3 5,5M19,19C17.7,18.9 16.4,18.6 15.2,18.2L16.4,17C17.2,17.2 18.1,17.4 19,17.4V19Z"/>
</svg>;

export const EditIcon = (props = {}) => <svg viewBox="0 0 24 24" {...props}>
    <path
        d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"/>
</svg>

export const CloseIcon = (props = {}) => <svg viewBox="0 0 24 24" {...props}>
    <path
        d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/>
</svg>;

export const UpIcon = (props = {}) => <svg viewBox="0 0 24 24" {...props}>
    <path d="M7,15L12,10L17,15H7Z"/>
</svg>;

export const DownIcon = (props = {}) => <svg viewBox="0 0 24 24" {...props}>
    <path d="M7,10L12,15L17,10H7Z"/>
</svg>;

export const UpDownIcon = (props = {}) => <svg viewBox="0 0 24 24" {...props}>
    <path d="M12,6L7,11H17L12,6M7,13L12,18L17,13H7Z"/>
</svg>;

export const EqualIcon = (props = {}) => <svg viewBox="0 0 24 24" {...props}>
    <path d="M19,10H5V8H19V10M19,16H5V14H19V16Z"/>
</svg>
export const MinusIcon = (props = {}) => <svg viewBox="0 0 24 24" {...props}>
    <path d="M19,13H5V11H19V13Z"/>
</svg>

export const AccountDetailsIcon = (props = {}) => <svg viewBox="0 0 24 24"{...props} >
    <path
        d="M11 9C11 10.66 9.66 12 8 12C6.34 12 5 10.66 5 9C5 7.34 6.34 6 8 6C9.66 6 11 7.34 11 9M14 20H2V18C2 15.79 4.69 14 8 14C11.31 14 14 15.79 14 18M22 12V14H13V12M22 8V10H13V8M22 4V6H13V4Z"/>
</svg>

export const CircleIcon = (props = {}) => <svg viewBox="0 0 24 24" {...props}>
    <path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"/>
</svg>;

export const LogoComeStai = (props = {}) => (
    <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <defs>
            <linearGradient x1="14.2" y1="32.76" x2="980.92" y2="219.44"
                            gradientUnits="userSpaceOnUse">
                <stop offset="0.26" stopColor="#29b5df"/>
                <stop offset="1" stopColor="#2996df"/>
            </linearGradient>
            <linearGradient x1="14.66" y1="30.34" x2="981.39" y2="217.02"/>
            <linearGradient x1="17.31" y1="16.63" x2="984.04" y2="203.31"/>
            <linearGradient x1="20.25" y1="1.4" x2="986.98" y2="188.08"/>
            <linearGradient x1="24.18" y1="-18.93" x2="990.91" y2="167.74"/>
            <linearGradient x1="25.83" y1="-27.5" x2="992.56" y2="159.18"/>
            <linearGradient x1="25.81" y1="-27.4" x2="992.54" y2="159.27"/>
            <linearGradient x1="29.41" y1="-46.05" x2="996.14" y2="140.63"/>
            <linearGradient x1="7.77" y1="66.05" x2="974.49" y2="252.73"/>
            <linearGradient x1="5.28" y1="78.94" x2="972.01" y2="265.61"/>
        </defs>
        <title>Tavola disegno 2</title>
        <path className="cls-1"
              d="M177.29,70.47a37,37,0,0,1,2.38-13,34.79,34.79,0,0,1,18-19.65,34.4,34.4,0,0,1,15-3.13,32.63,32.63,0,0,1,17.12,4.34,27.76,27.76,0,0,1,10.76,11.32l-10.71,7.37a18.23,18.23,0,0,0-3.43-5.1A17.33,17.33,0,0,0,222,49.36a18.94,18.94,0,0,0-4.85-1.76,23,23,0,0,0-4.95-.56,18.84,18.84,0,0,0-9.14,2.12,20.08,20.08,0,0,0-6.46,5.51,24,24,0,0,0-3.89,7.68,28.8,28.8,0,0,0-1.31,8.53A27.82,27.82,0,0,0,193,80a24.34,24.34,0,0,0,4.29,7.73,21.59,21.59,0,0,0,6.67,5.36,18.32,18.32,0,0,0,8.54,2,22.77,22.77,0,0,0,5.05-.6,19.73,19.73,0,0,0,5-1.92,18.89,18.89,0,0,0,4.39-3.38,15.45,15.45,0,0,0,3.23-5l11.42,6.56a21.65,21.65,0,0,1-4.9,7.12,31.81,31.81,0,0,1-7.17,5.21,36.82,36.82,0,0,1-8.54,3.23,37.89,37.89,0,0,1-8.89,1.11,30.6,30.6,0,0,1-14-3.23,36.74,36.74,0,0,1-11-8.44A39.19,39.19,0,0,1,179.87,84,36.81,36.81,0,0,1,177.29,70.47Z"/>
        <path className="cls-2"
              d="M274.06,107.85a29,29,0,0,1-11.61-2.22,26.09,26.09,0,0,1-8.74-6,26.43,26.43,0,0,1-5.5-8.74,29.28,29.28,0,0,1,0-20.91,26,26,0,0,1,14.24-14.75A29.21,29.21,0,0,1,274.06,53a28.9,28.9,0,0,1,11.57,2.22A26.11,26.11,0,0,1,299.82,70a29.42,29.42,0,0,1,0,20.91,27.13,27.13,0,0,1-5.45,8.74,25.58,25.58,0,0,1-8.69,6A29.08,29.08,0,0,1,274.06,107.85ZM260.22,80.47a18.36,18.36,0,0,0,1.07,6.42,15.42,15.42,0,0,0,2.93,5,13.07,13.07,0,0,0,19.69,0,15.35,15.35,0,0,0,2.93-5,18.83,18.83,0,0,0,1.06-6.46A18.45,18.45,0,0,0,286.84,74,15.4,15.4,0,0,0,283.91,69a13,13,0,0,0-4.39-3.28,12.83,12.83,0,0,0-5.46-1.16,12.39,12.39,0,0,0-5.45,1.21,13.36,13.36,0,0,0-4.39,3.33,15.35,15.35,0,0,0-2.93,5A18.5,18.5,0,0,0,260.22,80.47Z"/>
        <path className="cls-3"
              d="M393.06,106.84H379.52V77.14c0-4.24-.72-7.33-2.17-9.29a7.07,7.07,0,0,0-6-2.93A11.39,11.39,0,0,0,363.76,68a17,17,0,0,0-5.05,8v30.81H345.18V77.14c0-4.24-.73-7.33-2.18-9.29a7.06,7.06,0,0,0-6-2.93,11.52,11.52,0,0,0-7.52,3,16.52,16.52,0,0,0-5.1,8v30.91H310.83V53.91h12.22v9.8a20.21,20.21,0,0,1,8-7.93A24.05,24.05,0,0,1,342.75,53q6.67,0,10.31,3.24a14.41,14.41,0,0,1,4.64,8,23,23,0,0,1,8.18-8.33A22.11,22.11,0,0,1,377.2,53a15.76,15.76,0,0,1,8,1.82A13.24,13.24,0,0,1,390,59.57a20,20,0,0,1,2.37,6.67,43.48,43.48,0,0,1,.66,7.57Z"/>
        <path className="cls-4"
              d="M429.42,107.85a29.38,29.38,0,0,1-11.52-2.17,26.43,26.43,0,0,1-8.78-5.91,26.1,26.1,0,0,1-5.61-8.69,28,28,0,0,1-2-10.4A29.52,29.52,0,0,1,403.46,70,25.92,25.92,0,0,1,409,61.24a26.75,26.75,0,0,1,8.84-6A29.65,29.65,0,0,1,429.52,53a28.9,28.9,0,0,1,11.57,2.22,26.59,26.59,0,0,1,8.68,6,25.36,25.36,0,0,1,5.46,8.68,29,29,0,0,1,1.87,10.3c0,.88,0,1.72-.05,2.53a11.32,11.32,0,0,1-.25,2H415.88a15.87,15.87,0,0,0,1.52,5.56,13.64,13.64,0,0,0,7.47,6.77,14.43,14.43,0,0,0,5.06.9,15.51,15.51,0,0,0,7.62-2,10.41,10.41,0,0,0,4.9-5.2L454.07,94a23.72,23.72,0,0,1-9.35,10A29.06,29.06,0,0,1,429.42,107.85Zm13.74-31.92a14,14,0,0,0-4.4-9.54,13.43,13.43,0,0,0-9.44-3.59,13.64,13.64,0,0,0-5.1,1,13.48,13.48,0,0,0-7.17,6.83,14.56,14.56,0,0,0-1.37,5.35Z"/>
        <path className="cls-5"
              d="M509.42,53.91A17.57,17.57,0,0,0,506.85,52a31.65,31.65,0,0,0-4.55-2.38,39.56,39.56,0,0,0-5.81-2,25.27,25.27,0,0,0-6.36-.81q-5.76,0-8.59,2.12a7,7,0,0,0-2.82,6,6.12,6.12,0,0,0,1.06,3.69,9,9,0,0,0,3.08,2.58,25.91,25.91,0,0,0,5.1,2c2.05.61,4.43,1.25,7.12,1.93,3.5.93,6.68,2,9.55,3a25.88,25.88,0,0,1,7.27,4A16.66,16.66,0,0,1,516.49,78a19.24,19.24,0,0,1,1.62,8.33,20.81,20.81,0,0,1-2.17,9.86,18.26,18.26,0,0,1-5.86,6.61,25.48,25.48,0,0,1-8.49,3.69,42.76,42.76,0,0,1-10,1.16,53.89,53.89,0,0,1-16-2.43,49.26,49.26,0,0,1-14.14-6.86l6.16-12a19.37,19.37,0,0,0,3.29,2.38,48.63,48.63,0,0,0,5.6,2.93,43.12,43.12,0,0,0,7.18,2.47,32,32,0,0,0,8.08,1q11.5,0,11.51-7.37A6.06,6.06,0,0,0,502,83.81,11.15,11.15,0,0,0,498.21,81a35.44,35.44,0,0,0-5.86-2.22l-7.68-2.23a63.6,63.6,0,0,1-8.93-3.07,23.52,23.52,0,0,1-6.32-3.94,14.88,14.88,0,0,1-3.79-5.25,18.25,18.25,0,0,1-1.26-7.13,22.26,22.26,0,0,1,2-9.7A19.55,19.55,0,0,1,472,40.38a25.42,25.42,0,0,1,8.33-4.3,34.69,34.69,0,0,1,10.2-1.46A39.64,39.64,0,0,1,504.47,37a52,52,0,0,1,11.12,5.61Z"/>
        <path className="cls-6"
              d="M558.41,104.11a60,60,0,0,1-6.66,2.43,28.32,28.32,0,0,1-8.29,1.21,19.29,19.29,0,0,1-5.3-.7,11.8,11.8,0,0,1-4.34-2.28,11,11,0,0,1-3-4,14.63,14.63,0,0,1-1.11-6V64.31h-7V53.91h7V36.74h13.53V53.91h11.11v10.4H543.26V90.17q0,2.83,1.47,4a5.54,5.54,0,0,0,3.58,1.17,12.43,12.43,0,0,0,4.14-.71c1.35-.47,2.43-.87,3.24-1.21Z"/>
        <path className="cls-7"
              d="M579.93,107.85a20.09,20.09,0,0,1-7.17-1.26,17.18,17.18,0,0,1-5.76-3.54,16.46,16.46,0,0,1-3.79-5.3,16.07,16.07,0,0,1-1.36-6.67,14.7,14.7,0,0,1,1.66-6.92,16,16,0,0,1,4.65-5.4,23.19,23.19,0,0,1,7.12-3.49A31.12,31.12,0,0,1,584.37,74a40.56,40.56,0,0,1,6.92.59,29.09,29.09,0,0,1,6,1.72v-3c0-3.49-1-6.19-3-8.08s-4.93-2.83-8.84-2.83a23.42,23.42,0,0,0-8.28,1.52,38.29,38.29,0,0,0-8.28,4.45l-4.14-8.59a39.09,39.09,0,0,1,22-6.77q11.41,0,17.72,5.61t6.32,16.21V91.28a4.7,4.7,0,0,0,.76,3,3.31,3.31,0,0,0,2.47,1v11.51a30.3,30.3,0,0,1-6,.71,9,9,0,0,1-5.91-1.72,7.31,7.31,0,0,1-2.57-4.55l-.31-2.92a23.37,23.37,0,0,1-8.58,7.06A24.48,24.48,0,0,1,579.93,107.85Zm3.84-9.9a17.59,17.59,0,0,0,6.51-1.21,11.57,11.57,0,0,0,4.8-3.24,4.85,4.85,0,0,0,2.22-3.83V83.61a32.35,32.35,0,0,0-5.25-1.47,29.19,29.19,0,0,0-5.45-.55A14.47,14.47,0,0,0,578,84a7.13,7.13,0,0,0-3.33,6,7.24,7.24,0,0,0,2.62,5.71A9.57,9.57,0,0,0,583.77,98Z"/>
        <path className="cls-8" d="M623.46,46.54V33.1H637V46.54Zm0,60.3V53.91H637v52.93Z"/>
        <path className="cls-9"
              d="M45.65,146.85c-43,0-43-55-43-73s0-73,43-73C80,.77,142.71,24.24,145.16,71v5.53C142.71,123.39,80,146.85,45.65,146.85Zm0-141.63C19.73,5.22,7.12,27.65,7.12,73.81s12.61,68.6,38.53,68.6c32.88,0,92.76-22.06,95.07-66V71.11C138.41,27.28,78.53,5.22,45.65,5.22Z"/>
        <path className="cls-10"
              d="M69.4,142.83v-5a3.31,3.31,0,0,0,1.89-3v-6.24a2,2,0,0,1,1.79-2c11.54-1.2,18.14-6.24,22.08-16.87,1.57-4.23,3.85-12.15,6.12-21.18a3.2,3.2,0,0,0-1.13-3.31c1.42-5.87,5.08-21.52,5.08-27.55,0-10.81-8.16-12.64-14.62-12.94a6.21,6.21,0,0,0-4.79-2.69c-.41,0-.8-.06-1.16-.06-4.4,0-4.4,3.09-4.4,5.17s0,5.13,4.4,5.13c.36,0,.75,0,1.16-.06a6.26,6.26,0,0,0,4.73-2.6c8.18.45,9.8,3.31,9.8,8.05,0,3.85-1.88,13.91-4.92,26.39a3.18,3.18,0,0,0-2.55,2.37c-2.19,8.74-4.37,16.32-5.84,20.28-2.88,7.8-6.63,11.51-20.08,11.51s-17.19-3.71-20.08-11.51c-1.46-4-3.65-11.54-5.84-20.28A3.19,3.19,0,0,0,38.5,84.1c-3-12.48-4.93-22.54-4.93-26.39,0-4.74,1.63-7.6,9.8-8.05a6.28,6.28,0,0,0,4.73,2.6c.42,0,.81.06,1.16.06,4.4,0,4.4-3.09,4.4-5.17s0-5.13-4.4-5.13c-.35,0-.74,0-1.16.06a6.24,6.24,0,0,0-4.79,2.69c-6.45.3-14.62,2.13-14.62,12.94,0,6,3.66,21.68,5.09,27.55a3.18,3.18,0,0,0-1.13,3.31c2.26,9,4.55,17,6.12,21.18,3.93,10.63,10.53,15.67,22.07,16.87a2,2,0,0,1,1.8,2v6.24a3.32,3.32,0,0,0,1.88,3v5Z"/>
    </svg>)
