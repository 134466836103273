import React from 'react';
import {createUseStyles} from 'react-jss';
import {CircleIcon} from '../icons.js';

const useStyles = createUseStyles({
    root: {
        height: '100%',
        display: 'grid',
        gridAutoFlow: 'column',
        alignItems: 'center',
        width: 20,
        fill: ({score}) => {
            let value = '#EC4C47';
            if (score) {
                value = '#47B881';
            }
            return value
        },
    },
});

const ActivityLabel = ({score}) => {
    const classes = useStyles({score});

    return (
        <CircleIcon className={classes.root}/>
    );
};

export default ActivityLabel;
