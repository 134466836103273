import React, {Component} from 'react';
import Layout from "../Layout/Layout.component";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Redirect, withRouter} from "react-router-dom";
import * as actionCreators from "../../store/actions/usersActions";

class AuthenticatedRoute extends Component {

	static propTypes = {
		currentUser: PropTypes.object,
		setLoggedUser: PropTypes.func,
		updateUser: PropTypes.func
	};

	render() {
		const {currentUser} = this.props;
        if (currentUser && currentUser.permissions) {
            const {currentUser: {type}} = this.props;

            return (currentUser && type === 'business') ? <Redirect to={`/business-dash`}/> : <Layout {...this.props} />;
        } else {
            return <Redirect to={`/login`}/>;
        }

    }
		/* old

		return currentUser ? <Layout {...this.props} /> :  <Redirect to={`/login`}/>;
		*/


/*
	shouldComponentUpdate(nextProps, nextState){
		return !!nextProps.currentUser !== !!this.props.currentUser
	}
*/

}

const mapStateToProps = state => {
	return {
		currentUser: state.users.currentUser
	}
};

const mapDispatchToProps = dispatch => {
	return {
		setLoggedUser: (user) => dispatch(actionCreators.setLoggedUser(user)),
		/*updateUser: () => dispatch(actionCreators.updateUser())*/
	}
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoute));
