import React, {Component} from 'react';
import * as actionCreators from "../../store/actions/usersActions";
import {connect} from "react-redux";
import {Link, NavLink, withRouter} from "react-router-dom";
import {FaCog, FaPowerOff, FaUsersCog} from "react-icons/fa";
import LogoComeStai from "../../Media/logo-comestai-bianco.png";


class Header extends Component {

    state = {};

    componentDidMount() {
    }

    render() {
        /*console.log("header log: ", pathname);*/
        const {currentUser} = this.props;
        const {logout} = this;
        const isAdmin = (currentUser && currentUser.role === "admin");

        return <div className="header">
            <div className="page_title">
                <Link to={"/"}><img src={LogoComeStai} alt=""/></Link>
            </div>
            {currentUser && <div className={'actions'}>
                {isAdmin && <>
                    <NavLink exact={true} className="icon-link" to={"/account-list"}>
                        <FaUsersCog/>
                    </NavLink>
                    <NavLink exact={true} to={"/templates-list"}
                             className="icon-link">
                        <FaCog/>
                    </NavLink></>}
                <span className="icon-link" onClick={() => logout()}><FaPowerOff/></span>
            </div>}
        </div>
    }

    logout = async () => {
        const {logout,history} = this.props;
        await logout();
        history.push('/login')

    }
}


const mapStateToProps = state => {
    return {
        currentUser: state.users.currentUser
    }
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actionCreators.logout())
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)((Header)));
