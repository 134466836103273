import { jss } from 'react-jss';
import opensans from 'typeface-open-sans/files/open-sans-latin-400.woff2';
import opensansBold from 'typeface-open-sans/files/open-sans-latin-600.woff2';
import karla from 'typeface-karla/files/karla-latin-400.woff2';
import karlaBold from 'typeface-karla/files/karla-latin-700.woff2';

jss.createStyleSheet({
    '@global html, body': {
        fontFamily: "'Titillium Web', sans-serif",
    },
    '@global input,select': {
        fontFamily: 'inherit',
    },
    '@global body': {
        position: 'relative',
    },
    '@font-face': [{
        fontFamily: "'Titillium Web', sans-serif",
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontDisplay: 'fallback',
        src: `url(${opensans}) format('woff2')`,
    },{
        fontFamily: "'Titillium Web', sans-serif",
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontDisplay: 'fallback',
        src: `url(${opensansBold}) format('woff2')`,
    },{
        fontFamily: "'Titillium Web', sans-serif",
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontDisplay: 'fallback',
        src: `url(${karla}) format('woff2')`,
    },{
        fontFamily: "'Titillium Web', sans-serif",
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontDisplay: 'fallback',
        src: `url(${karlaBold}) format('woff2')`,
    }],
}).attach();


const columns = {
    'display': 'grid',
    'align-items': 'center',
    'grid-auto-flow': 'column',
    'grid-auto-columns': 'minmax(0, max-content)',
    'grid-column-gap': 10,
    'grid-row-gap': 10,
    'align-content': 'center',
    'justify-content': 'center',
    'justify-items': 'center',
};

export const actions = {
        ...columns,
        'align-items': 'center',
        'text-decoration': 'underline',
        'cursor': 'pointer',
        'color': 'blue',
};

export const paper = {
    borderRadius: 4,
    backgroundColor: '#fff',
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
};

export const button = {
    backgroundColor: '#2092c1',
    borderRadius: 2,
    border: 0,
    color: '#fff',
    padding: [10, 20],
    fontFamily: "'Titillium Web', sans-serif",
    fontSize: 18,
    fontWeight: 'bold',
    outline: 'none!important'
};

export const icon = {
    cursor: 'pointer',
    width: 30,
    fill: '#2092c1',
};
