import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import * as styles from '../theme.js';

import Spinner from "../Spinner.js";
import Grid from "../Grid";
import * as ReactGA from "react-ga";
import axios from "axios";
import {toMoney} from "../../Helpers/common";


const useStyles = createUseStyles({
    root: {
        position: "relative",
        margin: "0 auto",
        maxWidth: "1400px",
        padding: "20px"
    },

    title: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: 20,
        fontWeight: 'bold',
        color: '#3e515e',
        textAlign: 'center',
        padding: 30,
        marginBottom: 38,
        borderBottom: '1px solid #e4e7eb',
    },
    searchForm: {
        display: 'grid',
        gridTemplateColumns: '330px 110px auto 160px',
        gridColumnGap: 16,
        alignItems: 'center'
    },

    grid: {
        ...styles.paper,
        gridArea: 'grid',
        height: 'calc(100% - 12px)',
        // height: 'fit-content', // table automatically set correct borders if few rows
        overflow: 'hidden', // do not remove this! it's needed for availableHeight in <Grid />
    },


    upperThead: {
        display: 'grid',
        gridTemplateColumns: 'max-content max-content 1fr',
        gridColumnGap: '24px',
        alignItems: 'center',
        height: 56,
        padding: [0, 22], // must be same of grid padding
        backgroundColor: '#e6f1f6',

        '& > span:nth-child(1)': {
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            fontWeight: 'bold',
            color: '#3e515e',
        },

        '& > span:nth-child(2)': {
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            color: '#8b949a',
        },

        '& > label': {
            marginBottom: 0, // tofix: wrong resetter
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            fontWeight: 'bold',
            color: '#3e515e',
            justifySelf: 'right',

            display: 'grid',
            alignItems: 'center',
            gridColumnGap: 6,
            gridTemplateColumns: 'repeat(2, max-content)'
        },
    },

    thead: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: 16,
        fontWeight: 'bold',
        color: '#3e515e',
        borderBottom: [1, 'solid', '#ccc'],
    },

    td: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: 14,
        color: '#3e515e',
        '&:first-child': {
            fontWeight: 'bold',
        },
    },

    thActions: {
        flexBasis: '10%',
    },

    tdActions: {
        flexBasis: '10%',
    },

    statusColumn: {
        color: '#8b949a',
    },

    scoreColumn: {
        height: '100%',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'max-content max-content',
        gridGap: '10px',
        fontWeight: 'bold',
        '& > svg': {
            width: 12,
        },
    },
    btn: {
        maxWidth: 140,
        height: 40,
        borderRadius: 6,
        padding: 8,
        boxShadow: '0 2px 4px 0 rgba(62, 81, 94, 0.32)',
        backgroundColor: '#2092c1',
        borderColor: '#2092c1',
        color: '#fff',
        '&:hover:not(:disabled)': {
            backgroundColor: '#2083b1',
            borderColor: '#2092c1',
            color: '#fff',
            transition: 'ease-in 0.2s',
        },
        '&:disabled': {
            cursor: 'not-allowed',
        },
        '&:not(:disabled)': {
            '&:not(.disabled)': {
                '&:active': {
                    backgroundColor: '#2083b1',
                    borderColor: '#2092c1',
                    color: '#fff'
                }
            }
        }
    }

});

const defaultValues = {};

const BusinessOrderList = ({history}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);

    // grid state
    const [data, setData] = useState([]);
    /*const [filteredData, setFilteredData] = useState([]);*/
    /*const [filters, setFiltersData] = useState({name: '', active: -1});*/

    // grid state fetch
    useEffect(() => {
        if (process.env.REACT_APP_APP_ENVIRONMENT === 'production') {
            ReactGA.initialize("UA-44803163-27");
            ReactGA.set({
                page: history.location.pathname,
            });
            ReactGA.pageview(history.location.pathname);
        }
        (async () => {
            const data = await fetchData();
            setData(data);
            /*setFilteredData(data);*/
            setLoading(false)
        })();
    }, []);
    useEffect(() => {

    }, []);

    return (
        <div className={classes.root}>
            {loading && <Spinner center={true} overlay={true}/>}
            <h1 className={classes.title}>Lista Ordini</h1>
            {!loading &&
            <div className={classes.grid}>
                <div className={classes.upperThead}>
                    {/*<span>Ordini Eseguiti</span>
                    <span>{data.length} totali</span>*/}
                </div>
                <Grid
                    id={'grid'}
                    data={data}
                    /*updateData={setFilteredData} // needed for sorting!*/
                    rowHeight={56}
                    theadHeight={56}
                    upperTheadHeight={56}
                    maxRows={10}
                    rowPadding={24}
                    columnPadding={24}
                    columns={['id', 'created_at', 'invoice_id', 'count_email', 'total_amount']}
                    columnsNames={{
                        id: 'Identificativo',
                        created_at: 'Data creazione',
                        invoice_id: 'Id fatturazione',
                        count_email: 'Email inserite',
                        total_amount: 'Totale'
                    }}
                    columnsWidths={{
                        id: 20,
                        created_at: 20,
                        invoice_id: 20,
                        count_email: 20,
                        total_amount: 20
                    }}
                    classNames={{
                        thead: classes.thead,
                        td: classes.td,
                        thActions: classes.thActions,
                        tdActions: classes.tdActions,
                    }}
                    columnsFormatters={{
                        invoice_id: (invoice_id) => invoice_id === 0 ? 'non emessa' : invoice_id,
                        created_at: value => `${new Date(value).toLocaleDateString()}`,
                        total_amount: value => toMoney(value),
                        /* active: value => <ActivityLabel score={value}/>,*/
                    }}
                    columnsClasses={{
                        score: () => classes.scoreColumn,
                        status: () => classes.statusColumn,
                    }}
                    actionsLabel={''}
                    /* rowActionsRenderer={(row) => <NavLink
                         to={row.type === 'doctor' ? `/account/${row.id}` : `/account-business/${row.id}`}
                         style={{textAlign: 'right'}}>
                         <EditIcon style={{maxWidth: '24px'}} fill={'rgb(139, 148, 154)'}/>
                     </NavLink>}*/
                />
            </div>}
        </div>
    );

};

const fetchData = async () => {
    const {data} = await axios.get('/orders');
    console.log(data)
    return data;
};


export default BusinessOrderList;
