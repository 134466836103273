import React, {Component} from 'react';
import {FaEye, FaEyeSlash} from 'react-icons/fa';
import {cloneDeep} from 'lodash';
import {NODE} from '../../Constants'
import './Node.css';

class Node extends Component {

    constructor(props) {
        super(props)
        this.state = {
            opened: true
        }
    }

    render() {
        const {node, maxID} = this.props;
        const {opened} = this.state
        return (
            <div className="Node">
                <div className="name">
                    <button className="btn btn-secondary btn-sm" onClick={() => {
                        this.setState({opened: !opened})
                    }}>{opened ? <FaEye/> : <FaEyeSlash/>}</button>
                    <input className="form-control" type="text" value={node.text} placeholder="Write here" onChange={this.onInputChange}/>
                    <input type="checkbox" checked={node.multiple} onChange={this.onCheckbox}/>
                    <button className="btn btn-secondary btn-sm" onClick={this.addNode}>add</button>
                    <button className="btn btn-danger btn-sm" onClick={this.deleteNode}>remove</button>
                </div>
                {opened ? <div className="content">
                    {node.nodes.map((e, index) => {
                        if (e.nodes)
                            return <Node node={e} onChange={this.onChange}
                                         onDelete={this.props.onDelete}
                                         maxID={maxID} key={index}
                                         incrementMaxID={this.props.incrementMaxID} index={index}/>
                        else
                            return null
                    })}
                </div> : null}


            </div>
        );
    }

    onInputChange = (e) => {
        const props = cloneDeep(this.props);
        let {node, index} = props;
        node.text = e.target.value
        this.props.onChange(Number.isInteger(index) ? index : null, node)
    }

    onCheckbox = () => {
        const props = cloneDeep(this.props);
        let {node, index} = props;
        node.multiple = !node.multiple;
        this.props.onChange(Number.isInteger(index) ? index : null, node)
    };
    deleteNode = () => {
        const {node} = this.props;
        this.props.onDelete(node.id)
    };
    addNode = () => {
        const props = cloneDeep(this.props);
        let {node, index, maxID} = props;
        let newNode = NODE;
        newNode.id = maxID + 1;
        node.nodes.push(newNode);
        this.props.incrementMaxID(maxID + 1);
        this.props.onChange(Number.isInteger(index) ? index : null, node)
    }

    onChange = (childIndex, newNode) => {
        const props = cloneDeep(this.props);
        let {node, index} = props;
        node.nodes[childIndex] = newNode;
        this.props.onChange(Number.isInteger(index) ? index : null, node)
    }
}

export default Node;
