/*import {httpGetUserMe, httpLogoutUser, httpSendConfirmationEmail} from "../../HttpRequest/User.http.js"*/
/*import {httpLogoutUser} from "../../http/User.http";*/

import {httpLogoutUser} from "../../http/user.http";

export const SET_LOGGED_USER = 'SET_LOGGED_USER';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER = "UPDATE_USER";

export const setLoggedUser = (user) => {
    return {
        type: SET_LOGGED_USER,
        currentUser: user
    };
};

// ME API async code pattern
export const updateUser = (user) => {
    return {
        type: UPDATE_USER,
        currentUser: user
    };
};

/* LOGOUT async code pattern */
export const logoutSuccess = () => {
    return {
        type: LOGOUT,
        currentUser: null
    };
};

export const logout = () => {
    return async dispatch => {
        try {
            await httpLogoutUser();
            dispatch(logoutSuccess());
        } catch (e) {
            //todo: handle error
        }
    }
};


