import React from 'react';
import {createUseStyles} from 'react-jss';
import {CircleIcon} from '../icons.js';

const useStyles = createUseStyles({
    root: {
        color: ({score}) => {
            let value = '#3e515e';
            if (score > 6) {
                value = '#EC4C47';
            } else if (score > 3) {
                value = '#FDD007';
            }
            return value
        }
       /* height: '100%',
        display: 'grid',
        gridAutoFlow: 'column',
        alignItems: 'center',
        width: 20,
        fill: ({score}) => {
            let value = '#47B881';
            if (score > 6) {
                value = '#EC4C47';
            } else if (score > 3) {
                value = '#FDD007';
            }
            return value
        },*/
    },
});

const FrailtyScore = ({score}) => {
    const classes = useStyles({score});
    return (
        <span className={classes.root}>{score}</span>
    );
    /*return (
        <CircleIcon className={classes.root}/>
    );*/
};

export default FrailtyScore;
