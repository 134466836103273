import React from 'react';

let tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

tomorrow = `${tomorrow.getDate()}/${tomorrow.getMonth()+1}`;

const actions = [
    'Indicato ricovero',
    'Monitoraggio stretto',
    `Rivalutare il ${tomorrow}`,
    'Deceduto',
    'Ricoverato',
    'Guarito',
];

const ActionChange = ({ defaultValue, onChange = Function.prototype,fiscalCode,id }) => {

    return (
        <select onChange={onChange(fiscalCode ? fiscalCode : id)} defaultValue={defaultValue} style={{fontSize:16}}>
            <option value={null}>Seleziona nota</option>
            <option disabled={true}>--------</option>
            { actions.map(action => (
            <option key={action} value={action}>{action}</option>
            )) }
        </select>
    )
};

export default ActionChange;
