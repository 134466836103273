import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
/*import {useParams} from 'react-router';
import {useForm} from 'react-hook-form';*/
import {FaTrash} from "react-icons/fa";
/*import ActivityLabel from "../Account/ActivityLabel";
import {NavLink} from "react-router-dom";
import {EditIcon} from "../icons";*/
import * as ReactGA from "react-ga";
import axios from "axios";
import * as styles from "../../Pages/theme";
import Spinner from "../../Pages/Spinner";
import Grid from "../../Pages/Grid";
import Checkbox from "../Shared/Checkbox";


const useStyles = createUseStyles({
    root: {
        position: "relative",
        maxWidth: "1400px",
        padding: "20px 0"
    },

    searchForm: {
        display: 'grid',
        gridTemplateColumns: '330px 110px auto 160px',
        gridColumnGap: 16,
        alignItems: 'center'
    },

    grid: {
        ...styles.paper,
        gridArea: 'grid',
        height: 'calc(100% - 12px)',
        // height: 'fit-content', // table automatically set correct borders if few rows
        overflow: 'hidden', // do not remove this! it's needed for availableHeight in <Grid />
    },


    upperThead: {
        display: 'grid',
        gridTemplateColumns: 'max-content max-content 1fr',
        gridColumnGap: '24px',
        alignItems: 'center',
        height: 56,
        padding: [0, 22], // must be same of grid padding
        backgroundColor: '#e6f1f6',

        '& > span:nth-child(1)': {
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            fontWeight: 'bold',
            color: '#3e515e',
        },

        '& > span:nth-child(2)': {
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            color: '#8b949a',
        },

        '& > label': {
            marginBottom: 0, // tofix: wrong resetter
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            fontWeight: 'bold',
            color: '#3e515e',
            justifySelf: 'right',

            display: 'grid',
            alignItems: 'center',
            gridColumnGap: 6,
            gridTemplateColumns: 'repeat(2, max-content)'
        },
    },

    thead: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: 16,
        fontWeight: 'bold',
        color: '#3e515e',
        borderBottom: [1, 'solid', '#ccc'],
    },

    td: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: 14,
        color: '#3e515e',
        '&:first-child': {
            fontWeight: 'bold',
        },
    },

    thActions: {
        flexBasis: '10%',
    },

    tdActions: {
        flexBasis: '10%',
    },

    statusColumn: {
        color: '#8b949a',
    },

    scoreColumn: {
        height: '100%',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'max-content max-content',
        gridGap: '10px',
        fontWeight: 'bold',
        '& > svg': {
            width: 12,
        },
    },
    btn: {
        maxWidth: 140,
        height: 40,
        borderRadius: 6,
        padding: 8,
        boxShadow: '0 2px 4px 0 rgba(62, 81, 94, 0.32)',
        backgroundColor: '#2092c1',
        borderColor: '#2092c1',
        color: '#fff',
        '&:hover:not(:disabled)': {
            backgroundColor: '#2083b1',
            borderColor: '#2092c1',
            color: '#fff',
            transition: 'ease-in 0.2s',
        },
        '&:disabled': {
            cursor: 'not-allowed',
        },
        '&:not(:disabled)': {
            '&:not(.disabled)': {
                '&:active': {
                    backgroundColor: '#2083b1',
                    borderColor: '#2092c1',
                    color: '#fff'
                }
            }
        }
    },
    tables: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: '32px'
    },
    selected: {
        'display': 'grid',
        'align-items': 'center',
        'grid-auto-flow': 'column',
        'grid-auto-columns': 'minmax(0, max-content)',
        'grid-column-gap': '10px',
        'grid-row-gap': '10px',
        'align-content': 'center',
        'justify-content': 'center',
        'justify-items': 'center',
        '&::after': {
            'display': ({selected}) => selected > 0 ? 'inline-block' : 'none',
            'content': ({selected}) => `"${selected}"`,
            'font-size': 12,
            'color': '#fff',
            'background-color': '#888',
            'border-radius': 10,
            'padding': [3, 6],
        },
    },
    message:{
        gridArea: 'grid',
    }
});

const defaultValues = {};

const BusinessUsersEmails = () => {
    const [loading, setLoading] = useState(true);

    // grid state
    const [data, setData] = useState([]);
    const [lastSelectedId, setLastSelectedId] = useState(null);
    const classes = useStyles({loading, selected: data.filter(item => item.selected).length});
    /*const [filteredData, setFilteredData] = useState([]);*/
    /*const [filters, setFiltersData] = useState({name: '', active: -1});*/

    // grid state fetch
    useEffect(() => {
        (async () => {
            const data = await fetchData();
            setData(data);
            /*setFilteredData(data);*/
            setLoading(false)
        })();
    }, []);

    const selectionIsNotEmpty = data.some(item => item.selected);

    const selectAll = () => {
        data.forEach(item => item.selected = (selectionIsNotEmpty ? false : true));
        setData([...data]);
    };

    const selectRow = ({ id }) => event => {
        if (event.target.hasAttribute('data-onclick'))
            return;

        const item = data.find(item => item.id == id);
        item.selected = !item.selected;

        const lastItem = data.find(item => item.id == lastSelectedId);
        if (event.shiftKey && lastItem && lastItem.selected) {
            const min = id < lastSelectedId ? id : lastSelectedId;
            const max = id > lastSelectedId ? id : lastSelectedId;
            console.log(`selecting range ${min}-${max}`);
            data.forEach(item => {
                if (item.id >= min && item.id <= max)
                    item.selected = true;
            });
        }
        else {
            setLastSelectedId(id);
        }

        setData([...data]);
    };

    const deleteSelection = (email, global) => async () => {
        console.log('deletin in');
        const emails = global ? data.filter(item => item.selected).map(item => item.email) : [email];
        console.log('deleting', emails);

        /*if (!window.confirm(`You are deleting ${emails.length} users. Proceed?`))
            return;

        try {
            const {data: result} = await axios.delete('/emails/delete', {emails: emails});
            window.alert(`Operation succeeded.`);
            await fetchData();
            data.forEach(item => item.selected = false);
            setData(data);
        } catch (error) {
            window.alert(`An error occurred: "${error.message}"`);
        }*/
    };

    const removeBusinessUserEmail = async(email) => {
        if (!window.confirm(`You are deleting users. Proceed?`))
            return;

        try {
            const {data: result} = await axios.delete('/emails/delete', {
                data: {emails: [email]}
            });
            window.alert(`Operation succeeded.`);
            data.forEach(item => item.selected = false);
            setData(await fetchData());
        } catch (error) {
            window.alert(`An error occurred: "${error.message}"`);
        }
    };

    return (
        <div className={classes.root}>
            {loading && <Spinner center={true} overlay={true}/>}
            {(!loading && data.length) && <div className={classes.grid}>
                <div className={classes.upperThead}>
                    <span>Email registrate: </span>
                    <span>{data.length} email totali</span>
                </div>
                <Grid
                    id={'grid'}
                    data={data}
                    /*updateData={setFilteredData} // needed for sorting!*/
                    rowHeight={56}
                    theadHeight={56}
                    upperTheadHeight={56}
                    maxRows={10}
                    rowPadding={24}
                    columnPadding={24}
                    /*columns={['selected', 'email', 'actions']}*/
                    columns={['email']}
                    /*columnsNames={{
                        selected: '',
                        email: 'Email',
                        actions: ''
                    }}*/
                    columnsNames={{
                        email: 'Email',
                    }}
                    /*columnsWidths={{
                        selected: 10,
                        email: 30,
                        actions: 10
                    }}*/
                    columnsWidths={{
                        email: 60,
                    }}
                    classNames={{
                        thead: classes.thead,
                        td: classes.td,
                        thActions: classes.thActions,
                        tdActions: classes.tdActions,
                    }}
                    columnsClick={{
                        pm: value => () => console.log(value),
                    }}
                    headersFormatters={{
                        /*selected: <Checkbox className={classes.selected} data-onclick data-id={0}
                                            checked={selectionIsNotEmpty} onClick={selectAll}/>,*/
                       /* actions: selectionIsNotEmpty ?
                            <Actions global={true} deleteSelection={deleteSelection}/> : null,*/
                    }}
                    columnsFormatters={{
                       /* selected: (value, {email}) => <Checkbox data-checkbox data-id={email} checked={value}
                                                                      onClick={() => selectRow({email})}/>,*/
                        email: (value) => <span>{value}</span>,
                       /* actions: ''/!*(value, item, metadata) => <Actions {...item} {...metadata} global={true}
                                                                     deleteSelection={deleteSelection}/>*!/ // eslint-disable-line*/
                    }}
                   /* columnsFormatters={{
                        /!* paid: (paid) => paid === 0 ? 'non pagato' : 'pagato',
                         created_at: value => `${new Date(value).toLocaleDateString()}`,*!/
                        /!* active: value => <ActivityLabel score={value}/>,*!/
                    }}*/
                    columnsClasses={{
                        score: () => classes.scoreColumn,
                        status: () => classes.statusColumn,
                    }}
                    actionsLabel={''}
                    rowActionsRenderer={(row) =>
                        <FaTrash style={{maxWidth: '24px', cursor: 'pointer'}}
                                 onClick={() => removeBusinessUserEmail(row.email)}
                                 fill={'rgb(139, 148, 154)'}/>
                    }
                />
            </div>}
            {(!loading && !(data.length > 0)) &&
                <div className={classes.message}>
                    <h2> Non hai ancora registrato nessun utente.</h2>
                </div>
            }
        </div>
    );

};

const fetchData = async () => {
    const {data} = await axios.get('/employees/emails');
    console.log(data);
    return data;
};

const useStylesActions = createUseStyles(theme => ({
    root: theme.actions,
}));

const Actions = ({global, email, deleteSelection}) => {
    const classes = useStylesActions();
    console.log('global',global)
    console.log('email',email)

    return (
        <div className={classes.root}>
            {(global) && <FaTrash
                style={{maxWidth: '24px', cursor: 'pointer'}}
                data-onclick
                onClick={() => deleteSelection(email, global)}
                fill={'rgb(139, 148, 154)'}/>}
        </div>
    );
};

export default BusinessUsersEmails;
