import React, {Component} from 'react';
import classes from './TemplatesList.module.scss';
import SurveyCollapser from "../../Components/SurveyCollapser/SurveyCollapser.component";
import {httpCreateTemplateName, httpGetTemplateList} from "../../http/survey.http";
import {withRouter} from "react-router-dom";

class TemplatesList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            templates: [],
            newTemplate: {
                title: ''
            }
        }
    }

    componentDidMount() {
        this.getTemplates();
    }

    render() {
        const {templates, newTemplate} = this.state;
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 section-title">
                            <h2> Lista template </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="row">
                                {templates && templates.map((template, index) => {
                                    return <SurveyCollapser template={template} key={index} update={this.getTemplates}/>
                                })}
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className={classes.new_template}>
                                        <form onSubmit={this.newTemplate}>
                                            <input name={"title"} type="text" value={newTemplate.title}
                                                   placeholder="Nuovo template"
                                                   onChange={(event) => {
                                                       this.setState({
                                                           newTemplate: {
                                                               title: event.target.value
                                                           }
                                                       })
                                                   }}/>
                                            <button className="btn btn-success" type={'submit'}>
                                                Salva
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    newTemplate = async (event) => {
        event.preventDefault();
        const {newTemplate} = this.state;
        await httpCreateTemplateName(newTemplate.title);
        this.getTemplates();
    };

    getTemplates = async () => {
        const {data: templates} = await httpGetTemplateList();
        this.setState({
            templates: templates
        });
    }
}

export default withRouter(TemplatesList);
