import React from 'react';
import {Link} from 'react-router-dom';
import classes from './NotFound.module.scss';

const NotFound = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    let redirectPath = '/';
    let message = 'Torna alla dashboard';

    if (!user) {
        redirectPath = '/login';
        message = 'Torna al login'
    } else {
        switch (user.type) {
            case ('doctor' || 'admin') :
                redirectPath = '/';
                break;
            case 'business':
                redirectPath = '/business-dash';
                break;
        }
    }
    //switch case redirect link based on user type or logged or not

    return <section className={`${classes.fullPage}`}>
        <div className={`container ${classes.fullHeight}`}>
            <div className={`row align-items-center ${classes.fullHeight}`}>
                <div className="col-12 col-md-10 offset-md-1 text-center">
                    <h2 className={`${classes.title}`}>Non trovato!</h2>
                    <p className={`${classes.subtitle}`}>La pagina che stavi cercando non esiste.</p>
                    <Link className="btn btn-default" to={redirectPath}>{message}</Link>
                </div>
            </div>
        </div>
    </section>
};
export default NotFound;
