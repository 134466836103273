import React from 'react';
import {Link, withRouter} from "react-router-dom";
import classes from './Back.module.scss'


const Back = ({callback,to}) => {
    return <>
        {
        callback ?
            <span onClick={() => callback()} className={`${classes.link}`}>
                {`<`}Indietro
            </span> :
            <Link to={to} className={`${classes.link}`}>
                {`<`} Indietro
            </Link>
        }
    </>
};

export default withRouter(Back);
