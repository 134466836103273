export class Walk {

	constructor(maxID = 0){
		this.maxID = maxID
	}

	traverse(x) {
		if (this.isArray(x)) {
			this.traverseArray(x)
		} else if ((typeof x === 'object') && (x !== null)) {
			if(x.id && x.id >this.maxID)
				this.maxID = x.id;
			this.traverseObject(x)
		} else {

		}
	}

	traverseArray(arr) {
		arr.forEach((x) => {
			this.traverse(x)
		})
	}


	traverseObject(obj) {
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				this.traverse(obj[key])
			}
		}
	}

	isArray(o) {
		return Object.prototype.toString.call(o) === '[object Array]'
	}

}
