import React from 'react';
import {createUseStyles} from 'react-jss';
import {UpIcon, DownIcon} from '../icons.js';
import {MinusIcon} from "../icons";

const useStyles = createUseStyles({
    root: {
        width: 'max-content',
        height: '100%',
        display: 'grid',
        gridAutoFlow: 'column',
        alignItems: 'center',

        '& > span': {
            textAlign: 'right',
            width: 26,
        },
        '& > svg': {
            width: 24,
            fill: ({increment}) => {
                switch (increment) {
                    case -1:
                        return '#47B881';
                    case 1:
                        return '#EC4C47';
                    default:
                        return '#8B949A'
                }

            },
        }
    },
});

const ClinicalScore = ({score, increment}) => {
    const classes = useStyles({increment});
    let icon = <MinusIcon/>
    if (increment === -1) {
        icon = <DownIcon/>;
    } else if (increment === 1) {
        icon = <UpIcon/>;
    }
    return (
        <span className={classes.root}>
            <span>{score || '0'}</span>
            {increment !== undefined ? icon : null}
        </span>
    );
};

export default ClinicalScore;
