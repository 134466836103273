import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {Field, Formik} from "formik";
import qs from 'query-string'
import * as Yup from 'yup';
import classes from './Authentication.module.scss'
import * as actionCreators from "../../../store/actions/usersActions";
import {connect} from "react-redux";
import {httpLoginUser} from "../../../http/user.http";
import Back from "../../../Components/Shared/Back/Back.component";
import ReactGA from "react-ga";

class Login extends Component {

    state = {
        login: {
            email: "",
            password: '',
        },
        showAlert: false,
        alertMessage: "",
        isWarning: false
    };

    componentDidMount = () => {
        const {currentUser, history,location} = this.props;
        if(process.env.REACT_APP_APP_ENVIRONMENT === 'production') {
            ReactGA.initialize("UA-44803163-27");
            ReactGA.set({
                page:location.pathname,
            });
            ReactGA.pageview(location.pathname);
        }
        if (currentUser) {
            switch (currentUser.type) {
                case 'admin':
                    history.push("/");
                    break;
                case 'doctor':
                    history.push("/");
                    break;
                case 'business':
                    history.push("/business-dash");
                    break;
            }
        }
    };

    componentWillUnmount = () => {
    };

    //Todo: far comparire alert subito sotto header

    render = () => {
        const {handleSubmit} = this;
        const {login,alertMessage} = this.state;


        return <section className={`${classes.fullPage}`}>
            <div className={`container ${classes.fullHeight}`}>
                <div className={`row align-items-center ${classes.fullHeight}`}>
                    <div className="col-12 col-md-10 offset-md-1">
                        <div className={`row justify-content-center ${classes.card}`}>
                            <div className={`col-12 col-lg-6 ${classes.formWrapper}`}>
                                <Formik
                                    initialValues={login}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string()
                                            .email("email non valida")
                                            .required("l'email è obbligatoria"),
                                        password: Yup.string()
                                            .required("la password è obbligatoria")
                                    })}
                                    onSubmit={(values, {setSubmitting, setErrors}) => {
                                        handleSubmit(values, setErrors, setSubmitting);
                                    }}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          isSubmitting,
                                      }) => (
                                        <div>
                                            {/*{errors.message &&
                                <div className='alert-stretch alert-danger alert-offset'>
                                    {errors.message}<span className="alert-close">&times;</span>
                                </div>}*/}

                                            <form onSubmit={handleSubmit}>

                                                <h2 className={classes.title}>Login</h2>
                                                <p className={classes.subtitle}>Accedi alla piattaforma interna</p>
                                                <div className={`form-group ${classes.fieldWrap}`}>
                                                    <label htmlFor={'email'}>Email </label>
                                                    <Field name='email' type='email' placeholder={"tuaemail@example.com"}
                                                           className={((errors.email && touched.email) || errors.message) ? 'is-invalid' : null}/>
                                                    {errors.email && touched.email &&
                                                    <div className='invalid-feedback'>{errors.email}</div>}
                                                </div>
                                                <div className={`form-group ${classes.fieldWrap}`}>
                                                    <label htmlFor={'password'}>Password</label>
                                                    <Field name='password' type='password'
                                                           className={((errors.password && touched.password) || errors.message) ? 'is-invalid' : null}/>
                                                    {errors.password && touched.password &&
                                                    <div className='invalid-feedback'>{errors.password}</div>}
                                                </div>

                                                <div className={`form-group`}>
                                                    <Link className={`${classes.lostPassword}`}
                                                          to={'/auth/reset-password'}>Password dimenticata?</Link>
                                                </div>

                                                <div className={`form-group`}>
                                                    <button className={`btn btn-default`} type="submit"
                                                            disabled={isSubmitting}>
                                                        Accedi
                                                    </button>
                                                    <a className={`btn btn-outlined`} style={{marginLeft:'16px'}} href={`https://comestai.org/doctor-apply`} target={`_blank`}>Registrati</a>
                                                </div>
                                            </form>
                                        </div>
                                    )}
                                </Formik>
                                <p className={'invalid-feedback'}>{alertMessage}</p>
                            </div>
                            <div className={`col-12 col-lg-6 ${classes.background} d-none d-lg-block`}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    };

    handleSubmit = async (values, setErrors, setSubmitting) => {

        const {setLoggedUser, history, location} = this.props;
        const queryParams = qs.parse(location.search);
        setSubmitting(true);
        try {
            const {data: response} = await httpLoginUser(values);
            //axios.defaults.headers.common['Authorization'] = `Bearer ${response.access_token}`;
            localStorage.setItem('token', response.access_token);
            localStorage.setItem('user', JSON.stringify(response.user));
            setLoggedUser({
                ...response.user,
                token: response.access_token
            });
            this.setState({
                showAlert: false,
                alertMessage: "",
                isWarning: false
            });
            setSubmitting(false);
            //todo: usertype cpontrol
            history.push("/");


        } catch (error) {
            //setErrors(error.response.data);
                if(error.response && error.response.data && error.response.data.message){
                    this.setState({
                        showAlert: true,
                        alertMessage: error.response.data.message
                    });
                }else{
                    this.setState({
                        showAlert: true,
                        alertMessage: "Abbiamo avuto un problema. In caso persista contatta l'assistenza inviando un'email a info@comestai.org"
                    });
                }
            setSubmitting(false);
        }
    };
}

const mapStateToProps = state => {
    return {
        currentUser: state.users.currentUser
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLoggedUser: (user) => dispatch(actionCreators.setLoggedUser(user))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)((Login)));


