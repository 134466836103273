import React, {Component} from 'react';
import classes from './SurveyCollapser.module.scss';
import {Link} from "react-router-dom";
import {FaEye, FaTrash, FaEdit} from 'react-icons/fa';
import PropTypes from "prop-types";
import DeleteModal from "../DeleteModal/DeleteModal.component";
import {httpEditTemplateName} from "../../http/survey.http";
import SurveyExport from "../SurveyExport/SurveyExport.component";

class SurveyCollapser extends Component {

    static propTypes = {
        update: PropTypes.func,
        template: PropTypes.any
    };

    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            deleteModalOpened: false,
            type: null,
            onEdit: false,
            selectedId: null,
            title: this.props.template.title
        }
    }

    render() {
        const {collapsed, deleteModalOpened,type,selectedId,title} = this.state;
        const {template} = this.props;
        return (
            <div className="col-12">
                {deleteModalOpened && <DeleteModal type={type} id={selectedId} onClose={this.onCloseModal}/>}
                <div className={classes.collapser}>
                    <div className={classes.collapser_title} onClick={this.onCollapseClick}>
                        {<h3>{template.title}</h3>}
                       {/* <form onSubmit={this.editName}>
                            <input name={"title"} type="text" value={title}
                                   placeholder={title}
                                   onChange={(event) => {
                                       this.setState({
                                           newTemplate: {
                                               title: event.target.value
                                           }
                                       })
                                   }}/>
                        </form>*/}
                        {template.surveys.length > 0 && <Link to={`/generator/${template.id}/${template.surveys[0].id}`}
                                                              className="btn btn-edit"> Modifica </Link>}
                        {template.surveys.length === 0 &&
                        <Link to={`/generator/${template.id}`} className="btn btn-edit"> Modifica </Link>}
                        <button className="btn btn-delete" onClick={ () => this.onDeleteClick('template',template.id)}><FaTrash/></button>
                    </div>
                    {collapsed && <div className={classes.collapser_body}>
                        {template.surveys.map((survey, index) => {
                            return <div className={classes.survey_row} key={index}>
                                <p>{survey.title} - version: {survey.version}</p>
                                <Link to={`/generator/${template.id}/${survey.id}`} className="btn btn-view"> <FaEye/>
                                </Link>
                                <SurveyExport survey={JSON.parse(survey.json)} />
                                <button className="btn btn-delete" onClick={ () => this.onDeleteClick('survey',survey.id)}><FaTrash/></button>
                            </div>
                        })}
                    </div>}
                </div>
            </div>
        );
    }

    editName = async(event) => {
        event.preventDefault();
        await httpEditTemplateName(this.props.template.id, this.state.title);
    };

    onCloseModal = () => {
        this.setState({
            type: null,
            selectedId: null,
            deleteModalOpened: false
        });
        this.props.update();
    };

    onDeleteClick = (type,id) => {
        this.setState({
            type: type,
            selectedId: id,
            deleteModalOpened: true
        })
    };

    onCollapseClick = () => {
        const {collapsed} = this.state;
        this.setState({
            collapsed: !collapsed
        })
    }

}

export default SurveyCollapser;
