import React, {Component} from 'react';
import PropTypes from "prop-types";
import {httpDeleteSurvey, httpDeleteTemplate} from "../../http/survey.http";

class DeleteModal extends Component {

    static propTypes = {
        onClose: PropTypes.any,
        type: PropTypes.string,
        id: PropTypes.any,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <div className="overlay" onClick={this.props.onClose}/>
                <div className="center-modal">
                    <div className="modal-body">
                        <h4 className="text-center">Sei sicuro di volerlo eliminare? </h4>
                        <div className="btns-container">
                            <button type="button" className="btn btn-edit" onClick={this.props.onClose}>
                                Annulla
                            </button>
                            <button type="button" className="btn btn-delete" onClick={() => this.onDelete(this.props.type, this.props.id)}>
                                Conferma
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

    onDelete = async(type,id) => {
        switch (type) {
            case 'survey':
                await httpDeleteSurvey(id);
                break;
            case 'template':
                await httpDeleteTemplate(id);
                break;
            default:
               break;
        }

        this.props.onClose();
    };
}

export default DeleteModal;
