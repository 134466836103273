import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

const useStyles = createUseStyles({
    root: {
        position: 'relative',

        '&::before': {
            position: 'fixed',
            top: ({ positionY, marginY }) => positionY + marginY,
            left: ({ positionX }) => positionX,
            width: ({ width }) => width,
            height: ({ height }) => height,
            // top: ({ y, marginY }) => y === 'top' ? undefined : y === 'bottom' ? `calc(100% + ${marginY}px)` : '50%',
            // bottom: ({ y, marginY }) => y === 'bottom' ? undefined : y === 'top' ? `calc(100% + ${marginY}px)` : undefined,
            // left: ({ x, marginX }) => x === 'left' ? undefined : x === 'right' ? `calc(100% + ${marginX}px)` : '50%',
            // right: ({ x, marginX }) => x === 'right' ? undefined : x === 'left' ? `calc(100% + ${marginX}px)` : undefined,
            // transform: ({ x, y }) => {
            //     if (x === 'center' && y === 'center')
            //         return 'translate(-50%,-50%)';
            //     else if (x === 'center')
            //         return 'translateX(-50%)';
            //     else if (y === 'center')
            //         return 'translateY(-50%)';
            // },
            content: 'attr(aria-label)',
            fontSize: ({ fontSize }) => fontSize,
            lineHeight: '21px',
            // whiteSpace: 'pre',
            textAlign: ({ textAlign }) => textAlign,
            color: '#fff',
            backgroundColor: '#333',
            padding: 10,
            willChange: 'opacity',
            transition: 'opacity 0.5s ease',
            opacity: 0,
        },

        '&:not(:focus)::before': {
            zIndex: -1,
        },

        '&:focus::before, &:hover::before': {
            zIndex: 2,
            opacity: 1,
        },
    },
});

const Tooltip = ({ children, className, style = {}, content, width = 'auto', height = 'auto', x = 'right', y = 'top', marginX = 0, marginY = 0, textAlign = 'left', fontSize = 14, bypass = false }) => {
    const ref = useRef(null);
    const { x: positionX, y: positionY } = ref.current ? ref.current.getBoundingClientRect() : {};

    const classes = useStyles({ positionX, positionY, width, height, x, y, marginX, marginY, textAlign, fontSize });

    if (bypass)
        return children;

    return (
        <div className={cx(classes.root, className)} style={style} aria-label={content} ref={ref}>
            {children}
        </div>
    );
};

Tooltip.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    style: PropTypes.object,
    fontSize: PropTypes.number,
    content: PropTypes.string,
    width: PropTypes.any,
    height: PropTypes.any,
    y: PropTypes.oneOf(['top', 'center', 'bottom']),
    x: PropTypes.oneOf(['left', 'center', 'right']),
    marginX: PropTypes.number,
    marginY: PropTypes.number,
    textAlign: PropTypes.oneOf(['left', 'center', 'right']),
    bypass: PropTypes.bool,
};

export default Tooltip;
