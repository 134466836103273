import React, {Component} from 'react';
import Node from "../Node/Node";
import {Walk} from "../../Helpers/Walk";
import {cloneDeep} from 'lodash';
import {httpGetSurvey, httpSaveSurvey} from "../../http/survey.http";
import {NODE} from '../../Constants'
import {traverse} from "../../Helpers/deleteNode";
import classes from "./Generator.module.scss";
import Modal from "../Modal/Modal.component";

class Generator extends Component {

    constructor(props) {
        super(props);
        this.state = {
            maxID: 0,
            survey: null,
            surveyTitle: '',
            templateId: null,
            showSavedModal: false,
            modalTitle: null
        }
    }

    componentDidMount() {
        this.readQueryParam();
    }

    render() {
        const {survey, maxID, surveyTitle, showSavedModal,modalTitle} = this.state;
        return (
            <div>
                {showSavedModal && <Modal title={modalTitle} onClose={this.onCloseSaveModal}/>}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <label>Titolo</label>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <input name="title" className="form-control" type={'text'}
                                               placeholder="dai un titolo al questionario" value={surveyTitle}
                                               onChange={(event) => this.setState({
                                                   surveyTitle: event.target.value
                                               })}/>
                                    </div>
                                </div>
                                <div className={`col-6 text-left ${classes.save_button}`}>
                                    <button className="btn btn-success" onClick={this.saveSurvey}>Salva</button>
                                </div>
                            </div>
                            {survey && <div className="generator">
                                <Node node={survey} maxID={maxID}
                                      onDelete={this.onDelete}
                                      onChange={this.onChangeNode}
                                      incrementMaxID={this.incrementMaxID}/>
                                {/*<code>
                                    {JSON.stringify(survey)}
                                </code>*/}
                            </div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-right">
                            <button className="btn btn-success" onClick={this.saveSurvey}>Salva</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    readQueryParam = async () => {
        let surveyId = this.props.match.params.surveyId;
        let surveyParsed = NODE;
        if (surveyId) {
            const {data: survey} = await httpGetSurvey(surveyId);
            surveyParsed = JSON.parse(survey.json);
        }

        const walk = new Walk();
        walk.traverse(surveyParsed);
        this.setState({
            maxID: walk.maxID,
            survey: surveyParsed,
            templateId: this.props.match.params.templateId
        });
    };

    incrementMaxID = (maxID) => {
        this.setState({
            maxID: maxID
        })
    };

    onDelete = (id) => {
        const survey = cloneDeep(this.state.survey);
        const newSurvey = traverse(survey, id)
        this.setState({
            survey: newSurvey
        })
    };

    onChangeNode = (index, newNode) => {
        this.setState({
            survey: newNode
        })
    };

    saveSurvey = async () => {
        const {survey, surveyTitle, templateId,showSavedModal} = this.state;
        let timeStamp = new Date();
        timeStamp = `Revision of ${timeStamp.getDay()}-${timeStamp.getMonth()}-${timeStamp.getFullYear()} at ${timeStamp.getHours()}:${timeStamp.getUTCMinutes()}`;
        let surveyTosend = {
            survey: survey,
            title: surveyTitle ? surveyTitle : timeStamp,
            templateId: templateId
        };
        await httpSaveSurvey(surveyTosend);
        // todo: catch errors
        this.setState({
            modalTitle: surveyTosend.title,
            showSavedModal: !showSavedModal
        });
    };

    onCloseSaveModal = () => {
        const {showSavedModal} = this.state;
        this.setState({
            modalTitle: null,
            showSavedModal: !showSavedModal
        })
    }
}

export default Generator;
