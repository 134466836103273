import React, {Component} from 'react';
import {Route} from 'react-router-dom'
import Header from "../../Components/Header/Header.component";

class Layout extends Component {

    state = {};

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        const {location, history, component: Component, ...rest} = this.props;
        return <Route {...rest} render={matchProps => (
            <>
                <Header location={location} history={history}/>
                <Component {...matchProps} />
                {/* <Footer/>*/}
            </>
        )}/>
    }

}

export default Layout;
