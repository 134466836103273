import React, {useState, useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import axios from 'axios';
import * as styles from '../theme.js';
import {useParams} from 'react-router';
import {useForm} from 'react-hook-form';

import Spinner from "../Spinner.js";


const useStyles = createUseStyles({
    root: {
        position: 'relative',
        marginBottom: 20,
    },

    title: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: 20,
        fontWeight: 'bold',
        color: '#3e515e',
        textAlign: 'center',
        padding: 30,
        marginBottom: 38,
        borderBottom: '1px solid #e4e7eb',
    },

    form: {
        ...styles.paper,
        width: 940,
        margin: [0, 'auto'],
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        padding: 24,
        gridColumnGap: '24px',
        gridRowGap: '18px',

        '& hr': {
            width: 'calc(100% + 46px)',
            marginLeft: -24,
            marginTop: 0, //tofix: resetter
            marginBottom: 0, //tofix: resetter
            gridColumn: '1 / span 2',
        },
        '& textarea':{
            cursor: 'not-allowed'
        },
        '& input:not([type=checkbox]):not([type=radio]):not([type=range]),select,textarea': {
            width: 435,
            height: 48,
            borderRadius: 4,
            backgroundColor: 'rgba(139, 148, 154, 0.1)',
            border: 0,
            padding: [13, 16],
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 16,

            '&::placeholder': {
                opacity: 0.5,
            },
        },

        '& input[type=checkbox],input[type=radio]': {
            width: 'max-content',
        },

        '& input[type=range]': {
            width: '100%',
            appearance: 'none',
            height: 3,
            padding: '0 10px',
            borderRadius: 2,
            border: 0,
            outline: 'none',
            // https://mycolor.space/gradient3?ori=to+right&hex=%2347B881&hex2=%23FDD007&hex3=%23EC4C47&submit=submit
            backgroundImage: 'linear-gradient(to right, #47b881, #62c170, #80c85c, #a2ce46, #c7d12d, #dbc719, #eebb0c, #ffae11, #ff9521, #fe7c2f, #f7643c, #ec4c47)',
        },

        '& input[type="range"]::-webkit-slider-thumb': {
            appearance: 'none',
            width: 15,
            height: 15,
            borderRadius: '50%',
            backgroundImage: '-webkit-gradient(linear, left top, left bottom, color-stop(0, #fefefe), color-stop(0.49, #d7d7d7), color-stop(0.51, #d1d1d1), color-stop(1, #c8c8c8) )',
            border: '1px solid grey',
        },

        '& svg': {
            width: 20,
            fill: '#3e515e',
        },

        '& label': {
            height: 'max-content',
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 16,
            color: '#3e515e',
            marginBottom: 6,
            // in case label has multiple elements inside
            display: 'grid',
            gridAutoFlow: 'column',
            gridAutoColumns: 'max-content',
            gridColumnGap: '10px',
        },

        '& label[data-inline]': {
            height: 'max-content',
            display: 'grid',
            gridAutoFlow: 'column',
            gridAutoColumns: 'auto',
            alignItems: 'center',
            justifyContent: 'left',
            gridColumnGap: '14px',
            marginBottom: 4,
        },

        '& label > span': {
            fontFamily: "'Titillium Web', sans-serif",
            fontWeight: 'normal',
        },

        '& button': styles.button,
    },

    buttons: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'max-content max-content 1fr',
        gridColumnGap: '20px',
        gridColumn: '1 / span 2',
        gridAutoFlow: 'column',

        '& > button': {
            justifySelf: 'right',
        },
    },

    error: {
        color: 'red',
        display: 'grid',
        marginTop: 8
    },
});

const defaultValues = {};

const AccountForm = ({history}) => {
    const classes = useStyles();
    const {accountId} = useParams();

    const {register, handleSubmit,setError, errors, reset} = useForm({
        mode: 'onBlur',
        validateCriteriaMode: 'all',
        nativeValidation: true, // in order to use datalist with multiple values 1) type=email with native validation disabled 2) multiple=true
        defaultValues: process.env.NODE_ENV === 'development' ? defaultValues : undefined,
    });

    const [regions, setRegions] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [zips, setZips] = useState([]);
    // ready means all needed data for autocompletes is loaded
    const [ready, setReady] = useState(false);
    // loading
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const a = axios.get(`${process.env.REACT_APP_ENDPOINT}/regions`).then(({data}) => setRegions(data));
        const b = axios.get(`${process.env.REACT_APP_ENDPOINT}/provinces`).then(({data}) => setProvinces(data));
        const c = axios.get(`${process.env.REACT_APP_ENDPOINT}/zips`).then(({data}) => setZips(data));
        Promise.all([a, b, c]).then(() => {
            setReady(true);
            setLoading(false);
        });
    }, []);

    // if we are on /account/<id> fetch the account and init the form with its values
    useEffect(() => {
        if (!accountId || !ready)
            return;

        setLoading(true);

        Promise.all([regions, provinces, zips]).then(async () => {
            const {data} = await axios.get(`${process.env.REACT_APP_ENDPOINT}/user/${accountId}`);
            if(data.regions_id && data.regions_id !== 'null'){
                data.region = JSON.parse(data.regions_id || '[]').map(region_id => regions.find(region => Number(region.id) === Number(region_id)).name).join(',');
            }
            if(data.provinces && data.provinces !== 'null'){
                data.province = JSON.parse(data.provinces || '[]').map(province => provinces.find(p => p.sigla === province).provincia).join(',');
            }
            if(data.zips && data.zips !== 'null'){
                data.zip = JSON.parse(data.zips || '[]').join(',');
            }

            data.order_province = provinces.filter(province => province.sigla === data.order_province).map(e => e.provincia);

            reset(data);
            setLoading(false);
        });
    }, [ready]);

    const validateRegion = value => !value.length || value.split(',').every(regionSelected => regions.find(region => region.name === regionSelected)) || 'Scegli una regione tra quelle disponibili.';
    const validateProvinceMed = value => value.split(',').every(provinceSelected => provinces.find(province => province.provincia === provinceSelected)) || 'Scegli una provincia tra quelle disponibili per la regione.';
    const validateProvince = value => !value.length || value.split(',').every(provinceSelected => provinces.find(province => province.provincia === provinceSelected)) || 'Scegli una provincia tra quelle disponibili per la regione.';
    const validateZip = value => !value.length || value.split(',').every(zipSelected => zips.find(zip => zip.cap === zipSelected)) || 'Scegli un CAP tra quelli disponibili.';

    const onSubmit = async values => {
        values.regions_id = values.region ? values.region.split(',').map(regionName => regions.find(region => region.name === regionName).id) : '';
        values.provinces = values.province ? values.province.split(',').map(provinceName => provinces.find(province => province.provincia === provinceName).sigla) : '';
        values.zips = values.zip ? values.zip.split(',') : '';

        values.order_province = values.order_province.split(',').map(provinceName => provinces.find(province => province.provincia === provinceName).sigla)[0];
        try {
            setLoading(true);

            // create user if no accountId is in url, otherwise update existing user
            const endpoint = !accountId ? `${process.env.REACT_APP_ENDPOINT}/user/doctor` : `${process.env.REACT_APP_ENDPOINT}/user/doctor/${accountId}`;
            const {data} = await axios.post(endpoint, values);

            window.alert(`${!accountId ? 'Creazione' : 'Modifica'} avvenuta con successo.`);
            if (!accountId)
                history.push(`/account/${data.id}`);
        } catch (error) {

            if(error.response && error.response.data && error.response.data.error){
                Object.keys(error.response.data.error).map(e=>{
                    setError(e,'error',error.response.data.error[e])
                })
            }else{
                window.alert('Si è verificato un errore.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={classes.root}>
            {loading && <Spinner center={true} overlay={true}/>}

            <h1 className={classes.title}>Creazione account</h1>

            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                {!!accountId && <input type={'hidden'} name={'id'} value={accountId} ref={register}/>}

                <div className={classes.control}>
                    <label>Nome *</label>
                    <input type={'text'} name={'first_name'} placeholder={'Nome'} ref={register} required={true}/>
                    {errors.first_name && <small className={classes.error}>{errors.first_name.message}</small>}
                </div>

                <div className={classes.control}>
                    <label>Cognome *</label>
                    <input type={'text'} name={'last_name'} placeholder={'Cognome'} ref={register} required={true}/>
                    {errors.last_name && <small className={classes.error}>{errors.last_name.message}</small>}
                </div>

                <div className={classes.control}>
                    <label>Email *</label>
                    <input type={'email'} name={'email'} placeholder={'Email'} ref={register} required={true}/>
                    {errors.email && <small className={classes.error}>{errors.email.message}</small>}
                </div>

                <div className={classes.control}>
                    <label>Numero di telefono *</label>
                    <input type={'tel'} name={'mobile_number'} placeholder={'Numero di telefono'} ref={register}
                           required={true}/>
                    {errors.mobile_number && <small className={classes.error}>{errors.mobile_number.message}</small>}
                </div>

                <div className={classes.control}>
                    <label>Data di nascita *</label>
                    <input type={'date'} name={'birth_date'} placeholder={'Email'} ref={register} required={true}/>
                    {errors.birth_date && <small className={classes.error}>{errors.birth_date.message}</small>}
                </div>

                <div className={classes.control}>
                    <label>Documento di identità *</label>
                    <input type={'text'} name={'document_code'} placeholder={'Documento di identità'} ref={register}
                           required={true}/>
                    {errors.document_code && <small className={classes.error}>{errors.document_code.message}</small>}
                </div>

                <div className={classes.control}>
                    <label>Ordine della provincia di *</label>
                    <input type={'text'} name={'order_province'} placeholder={'Seleziona una provincia'}
                           multiple={false} required={true} list={'order_province'}
                           ref={register({validate: validateProvinceMed})} autoComplete={'off'}/>
                    <datalist id={'order_province'}>
                        {provinces.map(({provincia}) => <option key={provincia} value={provincia}>{provincia}</option>)}
                    </datalist>
                    {errors.order_province && <small className={classes.error}>{errors.order_province.message}</small>}
                </div>
                <div className={classes.control}>
                    <label>Matricola dell'ordine *</label>
                    <input type={'text'} name={'order_number'} placeholder={"Matricola dell'ordine"} ref={register}
                           required={true}/>
                    {errors.order_number && <small className={classes.error}>{errors.order_number.message}</small>}
                </div>

                <div className={classes.control}>
                    <label>Regione</label>
                    <input type={'text'} name={'region'} placeholder={'Seleziona una regione'} ref={register({validate: validateRegion})} multiple={true}
                           list={'region'} autoComplete={'off'}/>
                    <datalist id={'region'}>
                        {regions.map(({name}) => <option key={name} value={name}>{name}</option>)}
                    </datalist>
                    {errors.region && <small className={classes.error}>{errors.region.message}</small>}
                </div>

                <div className={classes.control}>
                    <label>Provincia</label>
                    <input type={'text'} name={'province'} placeholder={'Seleziona una provincia'} ref={register({validate: validateProvince})} multiple={true}
                           list={'province'} autoComplete={'off'}/>
                    <datalist id={'province'}>
                        {provinces.map(({provincia}) => <option key={provincia} value={provincia}>{provincia}</option>)}
                    </datalist>
                    {errors.province && <small className={classes.error}>{errors.province.message}</small>}
                </div>

                <div className={classes.control}>
                    <label>CAP</label>
                    <input type={'text'} name={'zip'} placeholder={'Seleziona un cap'} ref={register({validate: validateZip})} multiple={true} list={'zips'}
                           autoComplete={'off'}/>
                    <datalist id={'zips'}>
                        {zips.map(({cap}, index) => <option key={index} value={cap}>{cap}</option>)}
                    </datalist>
                    {errors.zip && <small className={classes.error}>{errors.zip.message}</small>}
                </div>
                {accountId ?<div className={classes.control}>
                    <label>Notes</label>
                    <textarea name="notes" id="notes" ref={register} readOnly={true}/>
                </div>: null}

                <hr/>
                {accountId ? <div className={classes.control}>
                    <label>Codice Medico *</label>
                    <input type={'text'} name={'doctor_code'} placeholder={'0000'} ref={register}
                           required={true}/>
                    {errors.doctor_code && <small className={classes.error}>{errors.doctor_code.message}</small>}
                </div> : null}
                <div className={classes.buttons}>
                    <label>
                        <input type={'checkbox'} name={'active'} ref={register}/>
                        <span>Abilita utente</span>
                    </label>

                    <label>
                        <input type={'checkbox'} name={'is_admin'} ref={register}/>
                        <span>Abilita privilegi di admin</span>
                    </label>

                    <button>Salva</button>
                </div>
            </form>
        </div>
    );
};

export default AccountForm;
