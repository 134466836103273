export function traverse(x, id) {
	if (isArray(x, id)) {
		traverseArray(x, id)
	} else if ((typeof x === 'object') && (x !== null)) {
		if (x.nodes.length > 0) {
			for (const i in x.nodes) {
				if (x.nodes[i].id === id) {
					x.nodes.splice(i, 1);
				}
			}
		}
		traverseObject(x, id)
	}
	return x
}

export function traverseArray(arr, id) {
	arr.forEach((x) => {
		traverse(x, id)
	})
}


export function traverseObject(obj, id) {
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			traverse(obj[key], id)
		}
	}
}

export function isArray(o) {
	return Object.prototype.toString.call(o) === '[object Array]'
}


