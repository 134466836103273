import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useForm} from 'react-hook-form';
import axios from 'axios';
import * as styles from "../theme";
import ProcessCompleted from "../../Components/Shared/ProcessCompleted/ProcessCompleted.component";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const useStyles = createUseStyles({
    root: {
        marginTop: '10vh'
    },
    title: {
        width: '242px',
        height: '64px',
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: '24px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.33',
        letterSpacing: '-0.1px',
        color: '#2092c1'
    },
    subtitle: {
        width: '300px',
        height: '60px',
        opacity: '0.48',
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        color: '#3e515e'
    },
    form: {
        ...styles.paper,
        width: 600,
        margin: [0, 'auto'],
        display: 'grid',
        gridTemplateColumns: '1fr',
        padding: 24,
        gridColumnGap: '24px',
        gridRowGap: '18px',

        '& hr': {
            width: 'calc(100% + 46px)',
            marginLeft: -24,
            marginTop: 0, //tofix: resetter
            marginBottom: 0, //tofix: resetter
            gridColumn: '1 / span 2',
        },

        '& input:not([type=checkbox]):not([type=radio]):not([type=range]),select': {
            width: 435,
            height: 48,
            borderRadius: 4,
            backgroundColor: 'rgba(139, 148, 154, 0.1)',
            border: 0,
            padding: [13, 16],
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 16,

            '&::placeholder': {
                opacity: 0.5,
            },
        },

        '& input[type=checkbox],input[type=radio]': {
            width: 'max-content',
        },

        '& input[type=range]': {
            width: '100%',
            appearance: 'none',
            height: 3,
            padding: '0 10px',
            borderRadius: 2,
            border: 0,
            outline: 'none',
            // https://mycolor.space/gradient3?ori=to+right&hex=%2347B881&hex2=%23FDD007&hex3=%23EC4C47&submit=submit
            backgroundImage: 'linear-gradient(to right, #47b881, #62c170, #80c85c, #a2ce46, #c7d12d, #dbc719, #eebb0c, #ffae11, #ff9521, #fe7c2f, #f7643c, #ec4c47)',
        },

        '& input[type="range"]::-webkit-slider-thumb': {
            appearance: 'none',
            width: 15,
            height: 15,
            borderRadius: '50%',
            backgroundImage: '-webkit-gradient(linear, left top, left bottom, color-stop(0, #fefefe), color-stop(0.49, #d7d7d7), color-stop(0.51, #d1d1d1), color-stop(1, #c8c8c8) )',
            border: '1px solid grey',
        },

        '& svg': {
            width: 20,
            fill: '#3e515e',
        },

        '& label': {
            height: 'max-content',
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 16,
            color: '#3e515e',
            marginBottom: 6,
            // in case label has multiple elements inside
            display: 'grid',
            gridAutoFlow: 'column',
            gridAutoColumns: 'max-content',
            gridColumnGap: '10px',
        },

        '& label[data-inline]': {
            height: 'max-content',
            display: 'grid',
            gridAutoFlow: 'column',
            gridAutoColumns: 'auto',
            alignItems: 'center',
            justifyContent: 'left',
            gridColumnGap: '14px',
            marginBottom: 4,
        },

        '& label > span': {
            fontFamily: "'Titillium Web', sans-serif",
            fontWeight: 'normal',
        },

        '& button': styles.button,
    },
    error: {
        color: '#ff3e3e',
        fontSize: 14,
        margin: [8, 0]
    },

});

const ChangePassword = () => {
    const classes = useStyles();
    const [changed, setChanged] = useState(false);
    const {register, handleSubmit, setError, errors} = useForm();

    const onSubmit = async ({current_password, new_password, new_confirm_password}) => {
        try {
            await axios.put('auth/password', {
                current_password: current_password,
                new_password: new_password,
                new_confirm_password: new_confirm_password,
            })
            setChanged(true)
        } catch (e) {
            if (e.response && e.response.data) {
                let errors = [];
                Object.keys(e.response.data).forEach(errorKey => {
                    errors.push({
                        name: errorKey,
                        message: e.response.data[errorKey][0]
                    });
                })
                setError(errors)
            }
        }

    }
    return (
        <main className={classes.root}>
            <div className="container">
                <div className="row">
                    <div className={classes.form}>
                        {changed ? <ProcessCompleted title={'Password cambiata con successo'} to={'/'} btnText={'Torna alla dashboard'} /> :
                        <>
                            <h2 className={classes.title}>Inserisci la nuova password</h2>
                            <p className={classes.subtitle}>La password deve avere minimo 8 caratteri.
                                Per renderla più potente, utilizza almeno una maiuscola, un numero e un carattere
                                speciale.</p>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label htmlFor="current_password">Password attuale</label>
                                    <input id={'current_password'} type="password" placeholder={'Password attuale'}
                                           name={'current_password'} ref={register}/>
                                    {errors.current_password &&
                                    <p className={classes.error}>{errors.current_password.message}</p>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="new_password">Nuova password</label>
                                    <input type="password" placeholder={'Nuova password'} id={'new_password'}
                                           name={'new_password'}
                                           ref={register}/>
                                    {errors.new_password &&
                                    <p className={classes.error}>{errors.new_password.message}</p>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="new_confirm_password">Conferma nuova password</label>
                                    <input id={'new_confirm_password'} type="password"
                                           placeholder={'Conferma nuova password'}
                                           name={'new_confirm_password'}
                                           ref={register}/>
                                    {errors.new_confirm_password &&
                                    <p className={classes.error}>{errors.new_confirm_password.message}</p>}
                                </div>


                                <button type={'submit'}>Cambia password</button>
                            </form>
                        </>}
                    </div>
                </div>
            </div>
        </main>
    );
};
ChangePassword.propTypes = {
};

export default withRouter(ChangePassword);
