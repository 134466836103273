import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {createPortal} from 'react-dom';
import {createUseStyles} from 'react-jss';
import Spinner from "../Spinner.js";
import {CloseIcon} from "../icons.js";
import * as styles from '../theme.js';
import axios from 'axios';
import useKey from 'use-key-hook';
import ClinicalScore from "./ClinicalScore";
import FrailtyScore from "./FrailtyScore";
import ActionChange from "./ActionChange";

const useStyles = createUseStyles({
    icon: {
        ...styles.icon,
        width: 30,
    },
    antialiased: {
        fontSmoothing: 'subpixel-antialiased',
        transform: 'translateZ(0) scale(1.0, 1.0)',
        width: '100%',
        position:'fixed',
        left: 0,
        top: 0,
        height: '100vh',
        zIndex: 100,
    },
    fullscreen: {
        position: 'fixed',
        top: 52, // header height
        left: 0,
        bottom: 0,
        right: 0,
    },

    overlay: {
        extend: 'fullscreen',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 4,
        display: 'grid',
        alignContent: 'center',
        justifyContent: 'center',
    },

    root: {
        extend: 'fullscreen',
        top: '50%',
        left: '50%',
        width: '90%',
        maxWidth: '1200px',
        height: 'calc(90% - 52px)',
        position: 'absolute',
        transform: 'translate(-50%,-50%)',
        zIndex: 5,
        backgroundColor: '#fff',
        overflow: 'auto',
    },

    header: {
        display: 'grid',
        gridTemplateColumns: '1fr min-content max-content min-content',
        gridColumnGap: '15px',
        alignItems: 'center',
        borderBottom: '1px solid #e4e7eb',
        padding: 24,

        fontFamily: "'Titillium Web', sans-serif",
        fontSize: 24,

        '& > h2': {
            fontFamily: "'Titillium Web', sans-serif",
            fontWeight: 'bold',
            fontSize: 24,
            color: '#3e515e',
            marginBottom: 0,
        },

        '& > small': {
            justifySelf: 'right',
        },

        '& > svg,small': {
            color: '#333',
            fontSize: 14,
            fill: '#333',
        },
    },

    main: {
        padding: 32,
    },

    general: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: 18,
        fontWeight: 'bold',
        color: '#3e515e',
        marginBottom: 15,
        display: 'grid',
        gridAutoFlow: 'column',
        alignItems: 'center',

        '& > select': {
            justifySelf: 'right',
        }
    },

    details: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: 16,
        color: '#3e515e',
        marginBottom: 20,
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: 'repeat(auto-fill, minmax(125px, 1fr))',
        gridColumnGap: '20px',
        gridRowGap: '10px',
        alignItems: 'start',

        '& span:last-child': {
            gridColumn: '1 / span 5',
            gridRow: '2',
        },
    },

    surveys: {
        height: 'calc(100vh * 0.5)',
        overflow: 'scroll',
    },

    survey: {
        paddingBottom: 15,
        marginBottom: 15,
        borderBottom: '1px solid #ccc',

        '&:first-child': {
            paddingTop: 15,
            marginTop: 15,
            borderTop: '1px solid #ccc',
        },

        '& > strong': {
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 16,
            fontWeight: 'bold',
            color: '#2092c1',
        },

        '& > div': {
            display: 'grid',
            gridColumnGap: '20px',
            gridAutoFlow: 'column',
            gridAutoColumns: 'max-content',
        },
    },

    status: {
        display: 'grid',
        gridAutoFlow: 'column',
        alignItems: 'center',
        gridColumnGap: '10px',
        gridAutoColumns: 'max-content',
    },

    footer: `
        height: 55px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #2092c1;
        color: white;
        width: 100%;
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        grid-column-gap: 30px;
        padding: 0 33px;
    `,

    footerTitle: {
        fontSize: 20,
    },
});

const AccountPopover = ({fiscal_code, onClose, onChangeAction,id}) => {
    const classes = useStyles();
    const [data, setData] = useState();

    useKey(onClose, {detectKeys: [27]});

    useEffect(() => {
        if(id){
            console.log(id)
            axios.get(`${process.env.REACT_APP_ENDPOINT}/employee/${id}/summary`).then(({data}) => setData(data));
        }else{
            console.log(fiscal_code)
            axios.get(`${process.env.REACT_APP_ENDPOINT}/patients/${fiscal_code}/summary`).then(({data}) => setData(data));
        }
    }, []);

    const {first_name, last_name, age, gender, height, weight, bmi, smoker = '', pathologies, email, mobile_number, zip, province, address, status, surveys} = data || {};

    const render = (
        <>
            <div className={classes.overlay} onClick={onClose}/>

            {data !== undefined &&
            <div className={classes.antialiased}>
                <section className={classes.root}>
                    {(surveys === undefined) && <Spinner center={true} overlay={true}/>}

                    <header className={classes.header}>
                        <h2>{first_name} {last_name} - {fiscal_code ? fiscal_code : data.fiscal_code}</h2>
                        <ActionChange onChange={onChangeAction} fiscalCode={fiscal_code} id={id} defaultValue={status}/>
                        <small>Premi ESC per chiudere</small>
                        <CloseIcon className={classes.icon} onClick={onClose}/>
                    </header>

                    <main className={classes.main}>
                        <div className={classes.details}>
                            <span><strong>Età:</strong> {age}</span>
                            <span><strong>Sesso:</strong> {gender}</span>
                            <span><strong>Altezza:</strong> {height}cm</span>
                            <span><strong>Peso:</strong> {weight}kg</span>
                            <span><strong>BMI:</strong> {bmi}</span>
                            <span><strong>Fumatore:</strong> {smoker.toLowerCase() === 'si' ? 'SI' : 'NO'}</span>
                            <span className={classes.status}><strong>Frailty score:</strong> <FrailtyScore
                                score={surveys[0].severity_score}/></span>
                            <span><strong>Patologie:</strong> {pathologies? pathologies : 'Nessuna patologia'}</span>
                        </div>

                        {Array.isArray(surveys) && !surveys.length &&
                        <p>Nessun questionario trovato per questo paziente.</p>
                        }

                        {Array.isArray(surveys) && surveys.length > 0 &&
                        <div className={classes.surveys}>
                            {surveys.map((survey, index) => ((
                                <div key={survey.id} className={classes.survey}>
                                    <strong>{surveys.length - index}. Questionario
                                        del {new Date(survey.date).toLocaleDateString()} | {survey.patient_status || 'N/A pre-anamnesi'}</strong>
                                    <p className={classes.surveyContent}>{survey.summary}</p>
                                    {survey.notes ? <><strong>Note aggiuntive del paziente:</strong>
                                    <p className={classes.surveyNotes}>{survey.notes}</p></>: null}
                                    <span className={classes.status}><strong>Clinical score:</strong> <ClinicalScore
                                        score={survey.covid_score}/></span>
                                </div>
                            )))}
                        </div>
                        }
                    </main>

                    <footer className={classes.footer}>
                        <strong className={classes.footerTitle}>Contatti</strong>
                        <span>Indirizzo: {address} - {zip} {province}</span>
                        <span>Email: <a href={`mailto:${email}`} style={{color:'#fff'}}>{email}</a> </span>
                        <span>Telefono: {mobile_number}</span>
                    </footer>
                </section>
            </div>
            }
        </>
    );

    return createPortal(render, document.body);
};

/*AccountPopover.propTypes = {
    fiscal_code: PropTypes.string.isRequired,
};*/

export default AccountPopover;
