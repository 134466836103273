import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import axios from 'axios';
import * as styles from '../theme.js';
import {useParams} from 'react-router';
import {useFieldArray, useForm} from 'react-hook-form';
import {FaTrash} from "react-icons/fa";

import Spinner from "../Spinner.js";


const useStyles = createUseStyles({
    root: {
        position: 'relative',
        marginBottom: 20,
    },

    title: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: 20,
        fontWeight: 'bold',
        color: '#3e515e',
        textAlign: 'center',
        padding: 30,
        marginBottom: 38,
        borderBottom: '1px solid #e4e7eb',
    },

    form: {
        ...styles.paper,
        width: 940,
        margin: [0, 'auto'],
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        padding: 24,
        gridColumnGap: '24px',
        gridRowGap: '18px',

        '& hr': {
            width: 'calc(100% + 46px)',
            marginLeft: -24,
            marginTop: 0, //tofix: resetter
            marginBottom: 0, //tofix: resetter
            gridColumn: '1 / span 2',
        },
        '& textarea': {
            cursor: 'not-allowed'
        },
        '& input:not([type=checkbox]):not([type=radio]):not([type=range]),select,textarea': {
            width: 435,
            height: 48,
            borderRadius: 4,
            backgroundColor: 'rgba(139, 148, 154, 0.1)',
            border: 0,
            padding: [13, 16],
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 16,

            '&::placeholder': {
                opacity: 0.5,
            },
        },

        '& input[type=checkbox],input[type=radio]': {
            width: 'max-content',
        },

        '& input[type=range]': {
            width: '100%',
            appearance: 'none',
            height: 3,
            padding: '0 10px',
            borderRadius: 2,
            border: 0,
            outline: 'none',
            // https://mycolor.space/gradient3?ori=to+right&hex=%2347B881&hex2=%23FDD007&hex3=%23EC4C47&submit=submit
            backgroundImage: 'linear-gradient(to right, #47b881, #62c170, #80c85c, #a2ce46, #c7d12d, #dbc719, #eebb0c, #ffae11, #ff9521, #fe7c2f, #f7643c, #ec4c47)',
        },

        '& input[type="range"]::-webkit-slider-thumb': {
            appearance: 'none',
            width: 15,
            height: 15,
            borderRadius: '50%',
            backgroundImage: '-webkit-gradient(linear, left top, left bottom, color-stop(0, #fefefe), color-stop(0.49, #d7d7d7), color-stop(0.51, #d1d1d1), color-stop(1, #c8c8c8) )',
            border: '1px solid grey',
        },

        '& svg': {
            width: 20,
            fill: '#3e515e',
        },

        '& label': {
            height: 'max-content',
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 16,
            color: '#3e515e',
            marginBottom: 6,
            alignItems: 'center',
            // in case label has multiple elements inside
            display: 'grid',
            gridAutoFlow: 'column',
            gridAutoColumns: 'max-content',
            gridColumnGap: '10px',
        },

        '& label[data-inline]': {
            height: 'max-content',
            display: 'grid',
            gridAutoFlow: 'column',
            gridAutoColumns: 'auto',
            alignItems: 'center',
            justifyContent: 'left',
            gridColumnGap: '14px',
            marginBottom: 4,
        },

        '& label > span': {
            fontFamily: "'Titillium Web', sans-serif",
            fontWeight: 'normal',
        },

        '& ul': {
            listStyle: 'none',
            padding: '0',
            '& li': {
                display: "grid",
                gridTemplateColumns: "1fr auto",
                alignItems: "end",
                gridColumnGap: "16px",
                margin: '0  0 24px 0',
                '& input':{
                    width: '100%!important',
                },
                '& button':{
                    backgroundColor: "red",
                    '&:disabled':{
                        backgroundColor: "#ccc",
                        cursor: 'not-allowed'
                    },
                   '& svg':{
                        fill: '#fff'
                   }
                },
            }
        },

        '& button': styles.button,
    },

    buttons: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'max-content 1fr',
        gridColumnGap: '20px',
        gridColumn: '1 / span 2',
        gridAutoFlow: 'column',

        '& > button': {
            justifySelf: 'right',
        },
    },

    error: {
        color: 'red',
        display: 'grid',
        marginTop: 8
    },
});

const defaultValues = {
   /* company_name: "FrancoSPA",
    vat_number: "1929120123021301234",
    email: "francesco.monti+1@kromin.it",
    mobile_number: "3234322345",
    address: "la casa sul campanile n12",
    city: "Broccostella",
    zip_code: "03030",
    month_price_per_person: "2",
    doctors_codes: ["", ""],
    has_his_own_doctor: true*/
};

const AccountBusinessForm = ({history}) => {
    const classes = useStyles();
    const {accountId} = useParams();

    const {register, handleSubmit, setError, errors, reset, control, getValues} = useForm(/*{
        mode: 'onBlur',
       /!* validateCriteriaMode: 'all',*!/
        nativeValidation: false, // in order to use datalist with multiple values 1) type=email with native validation disabled 2) multiple=true
        defaultValues: process.env.NODE_ENV === 'development' ? defaultValues : undefined,
    }*/);
    const {fields, append, remove} = useFieldArray({
        control,
        name: "doctors_codes"
    });

    const [regions, setRegions] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [zips, setZips] = useState([]);
    // ready means all needed data for autocompletes is loaded
    const [ready, setReady] = useState(false);
    // loading
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const a = axios.get(`${process.env.REACT_APP_ENDPOINT}/regions`).then(({data}) => setRegions(data));
        const b = axios.get(`${process.env.REACT_APP_ENDPOINT}/provinces`).then(({data}) => setProvinces(data));
        const c = axios.get(`${process.env.REACT_APP_ENDPOINT}/zips`).then(({data}) => setZips(data));
        Promise.all([a, b, c]).then(() => {
            setReady(true);
            setLoading(false);
        });
    }, []);

    // if we are on /account/<id> fetch the account and init the form with its values
    useEffect(() => {
        if (!accountId || !ready)
            return;

        setLoading(true);

        Promise.all([regions, provinces, zips]).then(async () => {
            const {data} = await axios.get(`${process.env.REACT_APP_ENDPOINT}/user/${accountId}`);
            /*if (data.regions_id && data.regions_id !== 'null') {
                data.region = JSON.parse(data.regions_id || '[]').map(region_id => regions.find(region => Number(region.id) === Number(region_id)).name).join(',');
            }
            if (data.provinces && data.provinces !== 'null') {
                data.province = JSON.parse(data.provinces || '[]').map(province => provinces.find(p => p.sigla === province).provincia).join(',');
            }*/
            /*if (data.zips && data.zips !== 'null') {
                data.zip = JSON.parse(data.zips || '[]').join(',');
            }*/
            try {
                reset(data);
            }catch (e) {
                console.log('errors')
            }finally {
                setLoading(false);
            }

         /*   data.order_province = provinces.filter(province => province.sigla === data.order_province).map(e => e.provincia);*/
        });
    }, [ready]);

    /*const validateRegion = value => !value.length || value.split(',').every(regionSelected => regions.find(region => region.name === regionSelected)) || 'Scegli una regione tra quelle disponibili.';
    const validateProvince = value => !value.length || value.split(',').every(provinceSelected => provinces.find(province => province.provincia === provinceSelected)) || 'Scegli una provincia tra quelle disponibili per la regione.';*/
    const validateZip = value => !value.length || value.split(',').every(zipSelected => zips.find(zip => zip.cap === zipSelected)) || 'Scegli un CAP tra quelli disponibili.';

    const onSubmit = async values => {
        /*values.regions_id = values.region ? values.region.split(',').map(regionName => regions.find(region => region.name === regionName).id) : '';
        values.provinces = values.province ? values.province.split(',').map(provinceName => provinces.find(province => province.provincia === provinceName).sigla) : '';*/
        /*values.zips = values.zip ? values.zip.split(',') : '';*/
        console.log(values)
        values.doctors_codes =  values.doctors_codes ? values.doctors_codes.filter( (item,index) => { return item !== ""}) : null;
        try {
            setLoading(true);

            // create user if no accountId is in url, otherwise update existing user
            const endpoint = !accountId ? `${process.env.REACT_APP_ENDPOINT}/user/business` : `${process.env.REACT_APP_ENDPOINT}/user/business/${accountId}`;
            const {data} = await axios.post(endpoint, values);

           /* window.alert(`${!accountId ? 'Creazione' : 'Modifica'} avvenuta con successo.`);*/
            if (!accountId){
               /* history.push(`/account-business/${data.id}`);*/
                history.push(`/account-list`);
            }
        } catch (error) {
            console.log("error",error.error);
            if (error.response && error.response.data && error.response.data.error) {
                Object.keys(error.response.data.error).map(e => {
                    setError(e, 'error', error.response.data.error[e])
                })
            } else {
                window.alert('Si è verificato un errore.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={classes.root}>
            {loading && <Spinner center={true} overlay={true}/>}
            {/* 'company_name', 'vat_code', 'address', 'zip_code', 'city', 'mobile_number', 'month_price_per_person', 'has_his_own_doctor', 'doctor_code'*/}
            <h1 className={classes.title}>Creazione Business account</h1>

            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate={false}>
                {!!accountId && <input type={'hidden'} name={'id'} value={accountId} ref={register}/>}

                <div className={classes.control}>
                    <label>Nome Azienda *</label>
                    <input type={'text'} name={'company_name'} placeholder={'Nome'} ref={register({ required: true })} />
                    {errors.company_name && <small className={classes.error}>Nome azienda obbligatorio</small>}
                </div>

                <div className={classes.control}>
                    <label>P. IVA *</label>
                    <input type={'text'} name={'vat_number'} placeholder={'Partita IVA'} ref={register({ required: true })}/>
                   {/* {errors.vat_number && <small className={classes.error}>{errors.vat_number.message}</small>}*/}
                    {errors.vat_number && <small className={classes.error}>La partita IVA è obbligatoria</small>}
                </div>

                <div className={classes.control}>
                    <label>Email *</label>
                    <input type={'email'} name={'email'} placeholder={'Email'}  ref={register({ required: true })}/>
                    {/*{errors.email && <small className={classes.error}>{errors.email.message}</small>}*/}
                    {errors.email && <small className={classes.error}>L'email è obbligatoria</small>}
                </div>

                <div className={classes.control}>
                    <label>Numero di telefono *</label>
                    <input type={'tel'} name={'mobile_number'} placeholder={'Numero di telefono'} ref={register({ required: true })}/>
                    {/*{errors.mobile_number && <small className={classes.error}>{errors.mobile_number.message}</small>}*/}
                    {errors.mobile_number && <small className={classes.error}>Il numero di telefono è obbligatorio</small>}
                </div>

                <div className={classes.control}>
                    <label>Indirizzo *</label>
                    <input type={'text'} name={'address'} placeholder={'Indirizzo'} ref={register({ required: true })} />
                   {/* {errors.address && <small className={classes.error}>{errors.address.message}</small>}*/}
                    {errors.address && <small className={classes.error}>L'indirizzo è obbligatorio</small>}
                </div>

                <div className={classes.control}>
                    <label>Città *</label>
                    <input type={'text'} name={'city'} placeholder={'Città'} ref={register({ required: true })} />
                    {errors.city && <small className={classes.error}>La città è obbligatoria</small>}
                </div>

                <div className={classes.control}>
                    <label>CAP*</label>
                    <input type={'text'} name={'zip_code'} placeholder={'Seleziona un cap'}
                           ref={register({validate: validateZip,required: true})} multiple={false} list={'zips'}
                           autoComplete={'off'}/>
                    <datalist id={'zips'}>
                        {zips.map(({cap}, index) => <option key={index} value={cap}>{cap}</option>)}
                    </datalist>
                    {errors.zip_code && <small className={classes.error}>{errors.zip_code.message}</small>}
                </div>

                <div className={classes.control}>
                    <label>Prezzo mensile per utenza</label>
                    <input type="number" min="0.00" max="10000.00" step="0.01" name={'month_price_per_person'}
                           placeholder={''} ref={register}/>
                    {errors.month_price_per_person &&
                    <small className={classes.error}>{errors.month_price_per_person.message}</small>}
                </div>

                {/*<div className={classes.control}>
                    <label>Regione</label>
                    <input type={'text'} name={'region'} placeholder={'Seleziona una regione'} ref={register({validate: validateRegion})} multiple={false}
                           list={'region'} autoComplete={'off'}/>
                    <datalist id={'region'}>
                        {regions.map(({name}) => <option key={name} value={name}>{name}</option>)}
                    </datalist>
                    {errors.region && <small className={classes.error}>{errors.region.message}</small>}
                </div>

                <div className={classes.control}>
                    <label>Provincia</label>
                    <input type={'text'} name={'province'} placeholder={'Seleziona una provincia'} ref={register({validate: validateProvince})} multiple={false}
                           list={'province'} autoComplete={'off'}/>
                    <datalist id={'province'}>
                        {provinces.map(({provincia}) => <option key={provincia} value={provincia}>{provincia}</option>)}
                    </datalist>
                    {errors.province && <small className={classes.error}>{errors.province.message}</small>}
                </div>*/}

                <ul className={classes.control}>
                    {fields.map((item, index) => {
                        return <li key={item.id}>
                            <div>
                                <label>Codice Medico #{index + 1}</label>
                                <input name={`doctors_codes[${index}]`}
                                       type='text'
                                       defaultValue={item.value}
                                       required={false}
                                       placeholder="codice medico"
                                       ref={register}/>
                            </div>
                            <button disabled={fields.length < 2} onClick={() => remove(index)}><FaTrash/></button>
                        </li>
                    })}
                    <button type="button" onClick={() => append()}>
                        Aggiungi
                    </button>
                </ul>

                <div className={classes.buttons}>
                    <label>
                        <input type={'checkbox'}
                               name={'has_his_own_doctor'}
                               ref={register}/>
                        <span>Con Dottore interno</span>
                    </label>

                    <button type={'submit'}>Salva</button>
                </div>
            </form>
        </div>
    );
};

export default AccountBusinessForm;
