import classes from "./ProcessCompleted.module.scss";
import React from 'react';
import {Link} from "react-router-dom";

const ProcessCompleted = ({title, message, btnText, to}) => {
    return <div>
                <h2 className={`${classes.title}`}>{title}</h2>
                <p className={`${classes.subtitle}`}>
                    {message}
                </p>
                {btnText && <div className="form-group">
                    <Link className={`btn btn-default`} to={to}>{btnText}</Link>
                </div>}
            </div>
};

export default ProcessCompleted;
