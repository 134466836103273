import axios from 'axios';

/**
 *
 * @param data
 * @param {String} data.email
 * @param {String} data.password
 */
export const httpLoginUser = (data) => {
    const url = "/auth/login";
    return axios.post(url, data);
};

/**
 * simple logout
 */
export const httpLogoutUser = () => {
    const url = "/auth/logout";
    return axios.get(url);
};

/**
 * get user info
 */
export const httpGetUserMe = () => {
    const url = "/auth/user";
    return axios.get(url);
};

/**
 *
 * request of password change
 */
export const httpResetPasswordRequest = (data) => {
    const url = "/password_recovery/create";
    return axios.post(url, data);
};

/**
 *
 * request of password change logged
 */
export const httpResetPasswordLogged = (data) => {
    const url = "user/change_password";
    return axios.post(url, data);
};

/**
 *
 * request of password change
 */
export const httpResetPasswordPermission = (token) => {
    const url = `/password_recovery/find/${token}`;
    return axios.get(url);
};

/**
 *
 * confirm and set new password
 */
export const httpResetPasswordConfirmation = (data) => {
    const url = "/password_recovery/reset";
    return axios.post(url, data);
};

/**
 *
 * confirm activation of auytorenew
 */
export const httpActivateAutoRenew = () => {
    const url = "/autorenew-subscription/activate";
    return axios.post(url);
};

/**
 *
 * confirm deactivation of auytorenew
 */
export const httpDeactivateAutoRenew = () => {
    const url = "/autorenew-subscription/deactivate";
    return axios.post(url);
};
