import React, {Component} from 'react';
import {FaFileExport} from "react-icons/fa";
import PropTypes from "prop-types";

class SurveyExport extends Component {

    static propTypes = {
        survey: PropTypes.any
    };

    state = {
        encodedCSV: null,
    };

    componentDidMount() {
        const {survey} = this.props;
        let {encodedCSV} = this.state;
        if (!encodedCSV) {
            let finalMap = this.parseSurvey(survey, 0);
            let arrayCSV = finalMap.join("\n");
            let csv = 'data:text/csv;charset=utf-8,' + arrayCSV;
            let encodedUri = encodeURI(csv);
            this.setState({
                encodedCSV: encodedUri
            })
        }
    }

    /*componentDidUpdate() {
        const {survey} = this.props;
        let {encodedCSV} = this.state;
        if (!encodedCSV) {
            let finalMap = this.parseSurvey(survey, 0);
            let arrayCSV = finalMap.join("\n");
            let csv = 'data:text/csv;charset=utf-8,' + arrayCSV;
            let encodedUri = encodeURI(csv);
            this.setState({
                encodedCSV: encodedUri
            })
        }
    }*/

    render() {
        let {encodedCSV} = this.state;
        return (
            <a className="btn btn-view" href={encodedCSV} download="export_survey.csv"> <FaFileExport/> </a>
        );
    }

    parseSurvey = (survey, level) => {
        let clonedMap = [];
        let currentLevel = level;
        let col = '';
        if (survey.id) {
            for (let j = 0; j < currentLevel; j++) {
                col = col + ';';
            }
            col = col + survey.text;
            clonedMap.push(col);
            if (survey.nodes) {
                survey.nodes.forEach((node) => {
                    col = ';';
                    for (let i = 0; i < currentLevel; i++) {
                        col = col + ';';
                    }
                    col = col + node.text;
                    clonedMap.push(col);
                    if (node.nodes && node.nodes.length > 0) {
                        clonedMap.push(...this.parseSurvey(node.nodes, currentLevel + 1));
                    }
                });
            }
        } else {
            survey.forEach((question) => {
                clonedMap.push(...this.parseSurvey(question, currentLevel + 1));
            })
        }
        return clonedMap;
    }
}

export default SurveyExport;
