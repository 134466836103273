import React from 'react';
import { createUseStyles } from 'react-jss';
import {AccountDetailsIcon} from "../icons";

const useStyles = createUseStyles({
    root: {
        height: '100%',
        display: 'grid',
        alignItems: 'center',
        textDecoration: 'underline',
        cursor: 'pointer',
        color: 'blue',
    },
});

const GridActions = () => {
    const classes = useStyles();

    return (
        <span className={classes.root}><AccountDetailsIcon width={'26px'} fill={'#2092c1'}/></span>
    );
};

export default GridActions;
