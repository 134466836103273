import React from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import './App.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import * as axios from "axios";
import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import usersReducer from "./store/reducers/users";
import {LOGOUT} from "./store/actions/usersActions";

Sentry.init({
    dsn: "https://ad66774c11c34d47a675afdb39743f7c@sentry.io/5178491",
    environment: process.env.REACT_APP_APP_ENVIRONMENT ? process.env.REACT_APP_APP_ENVIRONMENT : 'development'

});


const rootReducer = combineReducers({
    users: usersReducer
});
// for chrome ext
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT;
axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : null;
    return config;
});

axios.interceptors.response.use((response) => {
        return response
    },
    (error) => {
        if (error.response.status === 401) {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            store.dispatch({
                type: LOGOUT,
                currentUser: null
            })
        }
        return error
    })


ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={"/"}>
            <App/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
