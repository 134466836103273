import React, {useCallback, useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import cx from 'classnames';
import axios from 'axios';
import {useForm} from 'react-hook-form';
import {debounce} from 'lodash-es';
import {FaList} from "react-icons/fa";
import * as styles from '../theme.js';
import {GearIcon} from '../icons.js';
import {NavLink, withRouter} from "react-router-dom";
import Popup from "reactjs-popup";
import {SearchIcon} from "../icons";
import PropTypes from "prop-types";
import {connect} from "react-redux";

const useStyles = createUseStyles({
    root: {
        ...styles.paper,
        overflow: 'scroll',
        padding: 16,
        display: 'grid',

        '& hr': {
            width: 'calc(100% + 30px)',
            marginLeft: -15,
        },

        '& input:not([type=checkbox]):not([type=radio]):not([type=range]),select': {
            height: 35,
            borderRadius: 4,
            border: 'solid 1px rgba(139, 148, 154, 0.36)',
            backgroundColor: 'rgba(139, 148, 154, 0.1)',
            padding: [7, 10],
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            width: 'max-content',
        },

        '& input[type=checkbox],input[type=radio]': {
            width: 'max-content',
            height: 'max-content',
        },

        '& input[type=range]': {
            width: '100%',
            appearance: 'none',
            height: 3,
            padding: '0 10px',
            borderRadius: 2,
            border: 0,
            outline: 'none',
            // https://mycolor.space/gradient3?ori=to+right&hex=%2347B881&hex2=%23FDD007&hex3=%23EC4C47&submit=submit
            backgroundImage: 'linear-gradient(to right, #47b881, #62c170, #80c85c, #a2ce46, #c7d12d, #dbc719, #eebb0c, #ffae11, #ff9521, #fe7c2f, #f7643c, #ec4c47)',
        },

        '& input[type="range"]::-webkit-slider-thumb': {
            appearance: 'none',
            width: 15,
            height: 15,
            borderRadius: '50%',
            backgroundImage: '-webkit-gradient(linear, left top, left bottom, color-stop(0, #fefefe), color-stop(0.49, #d7d7d7), color-stop(0.51, #d1d1d1), color-stop(1, #c8c8c8) )',
            border: '1px solid grey',
        },

        '& svg': {
            width: 20,
            fill: '#3e515e',
        },

        '& label': {
            height: 'max-content',
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 14,
            fontWeight: 'bold',
            color: '#3e515e',
            marginBottom: 8,
        },

        '& label[data-inline]': {
            height: 'max-content',
            display: 'grid',
            gridAutoFlow: 'column',
            gridAutoColumns: 'auto',
            alignItems: 'center',
            justifyContent: 'left',
            gridColumnGap: '14px',
            marginBottom: 4,
        },

        '& label > span': {
            fontFamily: "'Titillium Web', sans-serif",
            fontWeight: 'normal',
        },

        '& button': {
            ...styles.button,
            justifySelf: 'right',
            margin: [20, 0],
        },

    },
    inviteBtn: {
        "cursor": "pointer",
        "background": "#e6f1f6",
        "color": "#3e515e",
        "padding": "2px 8px",
        "borderRadius": "4px",
        "fontWeight": "bold",
        "fontSize": "14px",
        '&:hover': {
            background: '#cbe9f6'
        }
    },
    control: {},

    userBox: {
        display: 'grid',
        gridTemplateAreas: `
            "name"
            "password"
            "doctor_code"
        `,
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'max-content',
        gridColumnGap: '15px',
        gridRowGap: '7px',
        alignItems: 'start',

        '& img': {
            gridArea: 'avatar',
            alignSelf: 'center',
            width: '100%',
            borderRadius: '50%',
        },
        '& strong': {
            gridArea: 'name',
            fontSize: 16,
            color: '#3a3b3f',
        },
        '& small': {
            gridArea: 'password',
            marginBottom: 10,
            opacity: 0.9,
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 10,
            color: '#2092c1',
            textDecoration: 'underline',
            '& svg': {
                fill: '#2092c1',
                width: 12,
            },
        },
    },
    business: {
        display: "grid",
        gridRowGap: "16px",
        '& a': {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        }
    }
});

const Filterbar = ({className, currentUser, businessFilter, view}) => {
    const classes = useStyles();

    const {register, handleSubmit} = useForm();

    const [statuses, setStatues] = useState([]);
    const [actions, setActions] = useState([]);
    const [pathologies, setPathologies] = useState([]);

    useEffect(() => {
        if (currentUser.type !== 'business') {

            const actionsFetch = (async () => {
                try {
                    const {data: actions} = view === 'patients' ? await axios.get(`${process.env.REACT_APP_ENDPOINT}/patients_actions`) : await axios.get(`${process.env.REACT_APP_ENDPOINT}/employees_actions`);
                    setActions(actions);
                } catch (error) {
                    setActions([]);
                }
            })();

            const statusesFetch = (async () => {
                const {data: statuses} = await axios.get(`${process.env.REACT_APP_ENDPOINT}/patients_statuses`);
                setStatues(statuses);
            })();

            const pathologiesFetch = (async () => {
                const {data: pathologies} = await axios.get(`${process.env.REACT_APP_ENDPOINT}/pathologies`);
                setPathologies(pathologies);
            })();

            Promise.all([actionsFetch, statusesFetch, pathologiesFetch]).then(() => onSubmit());
        }
    }, []);

    /*useEffect(() => {
        if (view) {
            const actionsFetch = (async () => {
                try {
                    const {data: actions} = view === 'patients' ? await axios.get(`${process.env.REACT_APP_ENDPOINT}/patients_actions`) : await axios.get(`${process.env.REACT_APP_ENDPOINT}/employees_actions`);
                    setActions(actions);
                } catch (error) {
                    setActions([]);
                }
            })();
            Promise.all([actionsFetch]).then(() => onSubmit());
        }
    }, [view]);*/

    // nudo e crudo, un evento listenato da Dashboard, un orrore per i giusti puristi flux
    const onSubmit = (values = {}) => {
        let filters = values;
        //fix for actions
        if (typeof filters.actions === 'string') {
            filters.actions = [filters.actions];
        } else if (typeof filters.actions === "boolean") {
            filters.actions = [];
        }
        document.dispatchEvent(new CustomEvent('dashboard-search', {detail: filters}));
    };

    const submit = () => handleSubmit(onSubmit)();
    const submitDebounced = useCallback(debounce(submit, 1000), []);

    return (
        <form className={cx(classes.root, className)} onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.userBox}>
                {currentUser && currentUser.type === 'business' ?
                    <div className={classes.business}>
                        <div>
                            <strong>{`${currentUser.company_name}`}</strong>
                        </div>

                        <div>
                            <NavLink
                                to={'/business-change-password'}>
                                <GearIcon/> Cambia password
                            </NavLink>
                        </div>
                        <NavLink to={'/business-order-list'}>
                            <FaList/>
                            I miei ordini
                        </NavLink>
                    </div>
                    :
                    <>
                        <strong>{`${currentUser.first_name} ${currentUser.last_name} `}</strong>
                        <small>
                            <NavLink to={'/change-password'}>
                                <GearIcon/> Cambia password
                            </NavLink>
                        </small>
                    </>}
                {!businessFilter && <>
                    <div style={{gridArea: 'doctor_code'}}>
                        <Popup trigger={<span className={classes.inviteBtn}>Invita paziente</span>} modal
                               contentStyle={{borderRadius: 4, maxWidth: 470}}>
                            {close => (
                                <div className="modal-doctor-code">
                                    <a className="close" onClick={close} style={{
                                        "color": "#000",
                                        "opacity": "1",
                                        "fontWeight": "100",
                                        "position": "relative",
                                        "top": "-4px",
                                        "right": "4px",
                                        "cursor": "pointer"
                                    }}>
                                        &times;
                                    </a>
                                    <div style={{padding: 24}}>
                                        <h4 style={{
                                            "fontSize": "16px",
                                            "fontWeight": "600",
                                            "fontStretch": "normal",
                                            "fontStyle": "normal",
                                            "lineHeight": "1.5",
                                            "letterSpacing": "0.1px",
                                            "color": "#3e515e"
                                        }}>Invita un tuo paziente</h4>
                                        <p style={{
                                            "fontSize": "14px",
                                            "fontWeight": "normal",
                                            "fontStretch": "normal",
                                            "fontStyle": "normal",
                                            "lineHeight": "1.43",
                                            "letterSpacing": "normal",
                                            "color": "#3e515e"
                                        }}>Inviando il link qui di seguito ai tuoi pazienti, riceverai il loro
                                            questionario in
                                            modalità esclusiva. Gli altri medici della piattaforma non potranno
                                            visionare il
                                            questionario contenente il tuo codice medico personale.
                                        </p>
                                        <input type="text" contentEditable={false}
                                               onFocus={(e) => e.target.select()}
                                               value={`https://comestai.org/${currentUser.doctor_code}`}
                                               style={{width: 270}}/>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </div>
                </>}
                {/*<span>Codice medico: {currentUser.doctor_code}</span>*/}
            </div>

            {!businessFilter && <>
                <hr/>
                <div className={classes.control}>
                    <label>Nome e cognome o CF</label>

                    <label data-inline={true}>
                        <input type={'text'} name={'q'} placeholder={'Nome e cognome, CF'} ref={register}
                               onChange={submitDebounced}/>
                        <SearchIcon/>
                    </label>
                </div>

                <hr/>

                <div className={classes.control}>
                    <label>Soggetto ad alto rischio</label>

                    <label data-inline={true}>
                        <input id={'severe_conditions'} type={'checkbox'} name={'severe_conditions'} ref={register}
                               onChange={submit}/>
                        <span htmlFor={'severe_conditions'}>Soggetto alto rischio</span>
                    </label>
                </div>

                <hr/>

                <div className={classes.control}>
                    <label>Sesso</label>

                    <label data-inline={true}>
                        <input id={'gender_any'} type={'radio'} name={'gender'} ref={register} value={''}
                               onChange={submit}
                               defaultChecked={true}/>
                        <span htmlFor={'gender_any'}>Entrambi</span>
                    </label>

                    <label data-inline={true}>
                        <input id={'gender_uomo'} type={'radio'} name={'gender'} ref={register} value={'uomo'}
                               onChange={submit}/>
                        <span htmlFor={'gender_uomo'}>Uomo</span>
                    </label>

                    <label data-inline={true}>
                        <input id={'gender_donna'} type={'radio'} name={'gender'} ref={register} value={'donna'}
                               onChange={submit}/>
                        <span htmlFor={'gender_donna'}>Donna</span>
                    </label>
                </div>

                <hr/>

                <div className={classes.control}>
                    <label>Aumento di score</label>

                    <label data-inline={true}>
                        <input id={'increment_day'} type={'checkbox'} name={'increment_day'} ref={register}
                               onChange={submit}/>
                        <span htmlFor={'increment_day'}>Aumento score rispetto giorno precedente</span>
                    </label>
                </div>
                <hr/>
                <div className={classes.control}>
                    <label>Solo con mio codice medico</label>

                    <label data-inline={true}>
                        <input id={'only_mine'} type={'checkbox'} name={'only_mine'} ref={register}
                               onChange={submit}/>
                        <span htmlFor={'only_mine'}>Pazienti che hanno inserito il mio codice medico</span>
                    </label>
                </div>

                <hr/>

                {actions.length ?
                    <>
                        <div className={classes.control}>
                            <label>Nota</label>

                            {actions.map((action, id) => (
                                <label key={id} data-inline={true}>
                                    <input id={id} type={'checkbox'} name={'actions'} ref={register}
                                           value={action}
                                           onChange={submit}/>
                                    <span htmlFor={id}>{action}</span>

                                </label>
                            ))}
                        </div>
                        <hr/>
                    </>
                    : null}


                <div className={classes.control}>
                    <label>Pre-anamnesi</label>

                    {statuses.map(({id, short_title}) => (
                        <label key={id} data-inline={true}>
                            <input id={id} type={'checkbox'} name={'patients_statuses'} ref={register} value={id}
                                   onChange={submit}/>
                            <span htmlFor={id}>{short_title}</span>
                        </label>
                    ))}
                </div>

                <hr/>

                <div className={classes.control}>
                    <label>Patologie</label>

                    {pathologies.map(({id, name}) => (
                        <label key={id} data-inline={true}>
                            <input id={name} type={'checkbox'} name={'pathologies'} ref={register} value={id}
                                   onChange={submit}/>
                            <span htmlFor={name}>{name}</span>
                        </label>
                    ))}
                </div>
            </>}

            {/*<button>Cerca</button>*/}
        </form>
    );
};

Filterbar.propTypes = {
    className: PropTypes.string,
};

const mapStateToProps = state => {
    return {
        currentUser: state.users.currentUser
    }
}

export default withRouter(connect(mapStateToProps, null)((Filterbar)));
