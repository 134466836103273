import React, {Component} from 'react';
import Layout from "../Layout/Layout.component";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Redirect, withRouter} from "react-router-dom";
import * as actionCreators from "../../store/actions/usersActions";

class AdminRoute extends Component {

    static propTypes = {
        currentUser: PropTypes.object,
        setLoggedUser: PropTypes.func,
        updateUser: PropTypes.func
    };

    componentWillUnmount() {}

    render() {
        const {currentUser} = this.props;
        if(currentUser && currentUser.permissions){
            const {currentUser:{permissions:{canAdminister}}} = this.props;

            return currentUser && canAdminister ? <Layout {...this.props} /> : <Redirect to={`/`}/>
        }else{
            return <Redirect to={`/login`}/>;
        }
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.users.currentUser
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLoggedUser: (user) => dispatch(actionCreators.setLoggedUser(user)),
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminRoute));
