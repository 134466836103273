import React, {Component} from 'react';
import './App.scss';
import PropTypes from "prop-types";
import Dashboard from "./Pages/Dashboard/Dashboard.js";
import AccountForm from "./Pages/Account/AccountForm.js";
import AccountList from "./Pages/Account/AccountList.js";
import Generator from "./Components/Generator/Generator.component";
import NotFound from "./Pages/NotFound/NotFound.page";
import Login from "./Pages/Auth/Login/Login.component";
import PasswordReset from './Pages/Auth/PasswordReset/PasswordReset.page';
import PasswordResetConfirm from './Pages/Auth/PasswordResetConfirm/PasswordResetConfirm.page';
import {Redirect, Switch, withRouter} from "react-router-dom";
import AuthenticatedRoute from "./Containers/AuthenticatedRoute/AuthenticatedRoute";
import PublicLayout from "./Containers/PublicLayout/PublicLayout";
import AdminRoute from "./Containers/AdminRoute/AdminRoute.component";
import * as usersActionCreators from "./store/actions/usersActions";
import {connect} from "react-redux";
import TemplatesList from "./Pages/TemplatesList/TemplatesList.component";
import ChangePassword from "./Pages/Auth/ChangePassword.component";
import AccountBusinessForm from "./Pages/Account/AccountBusinessForm";
import BusinessRoute from "./Containers/BusinessRoute/BusinessRoute";
import BusinessDash from "./Pages/BusinessDash/BusinessDash";
import BusinessOrder from "./Pages/BusinessOrder/BusinessOrder";
import BusinessOrderList from "./Pages/BusinessOrderList/BusinessOrderList";
import BusinessAddDoctor from "./Pages/BusinessAddDoctor/BusinessAddDoctor";
import {httpGetUserMe} from "./http/user.http";

class App extends Component {

    static propTypes = {
        currentUser: PropTypes.object,
        setLoggedUser: PropTypes.func,
        updateUser: PropTypes.func,
        getCountries: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (window.customerlySettings) {
                window.customerlySettings.user_id =  user.doctor_code;
                window.customerlySettings.name = `${user.first_name} ${user.last_name}`;
                window.customerlySettings.email = user.email;
            }
            this.props.setLoggedUser(user);
        }
        window.customerly('update');
    }

    componentDidMount = async () => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            await this.userMe()
        }
    };

    componentDidUpdate = async (prevProps) => {
        const {currentUser} = this.props;
        if (this.props.location !== prevProps.location) {
            if (currentUser) {
                this.userMe();
            }
        }
    };

    render() {
        return (
            <Switch>
                <AuthenticatedRoute exact path="/change-password" component={ChangePassword}/>
                <AdminRoute exact path="/account" component={AccountForm}/>
                <AdminRoute exact path="/account/:accountId" component={AccountForm}/>
                <AdminRoute exact path="/business-registration" component={AccountBusinessForm}/>
                <AdminRoute exact path="/account-business/:accountId" component={AccountBusinessForm}/>
                <AdminRoute exact path="/account-list" component={AccountList}/>
                <AdminRoute exact path="/templates-list" component={TemplatesList}/>
                <AdminRoute exact path="/generator/:templateId/:surveyId" component={Generator}/>
                <AdminRoute exact path="/generator/:templateId/" component={Generator}/>
                <BusinessRoute exact path={"/business-change-password"} component={ChangePassword}/>
                <BusinessRoute exact path={"/business-dash"} component={BusinessDash}/>
                <BusinessRoute exact path={"/business-order"} component={BusinessOrder}/>
                <BusinessRoute exact path={"/business-order-list"} component={BusinessOrderList}/>
                <BusinessRoute exact path={"/add-business-doctor"} component={BusinessAddDoctor}/>
                <AuthenticatedRoute exact path="/" component={Dashboard}/>
                <PublicLayout exact path="/auth/reset-password" component={PasswordReset}/>
                <PublicLayout exact path="/auth/reset-password-confirm/:token"
                              component={PasswordResetConfirm}/>
                <PublicLayout exact path="/login" component={Login}/>
                <PublicLayout exact path="/404" component={NotFound}/>
                <Redirect to="/404"/>
            </Switch>
        );
    }

    userMe = async () => {
        const {updateUser} = this.props;
        const {data: user} = await httpGetUserMe();
        const token = localStorage.getItem('token');
        if (token) {
            updateUser(user);
        }
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.users.currentUser,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLoggedUser: (user) => dispatch(usersActionCreators.setLoggedUser(user)),
        logout: () => dispatch(usersActionCreators.logout()),
        updateUser: (user) => dispatch(usersActionCreators.updateUser(user))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
