import axios, {post, get, put} from 'axios';

/**
 * get a specific survey
 * @param id
 * @returns {*}
 */
export const httpGetSurvey = (id) => {
    const url = "/surveys/" + id;
    return get(url);
};

/**
 * to delete a specific survey
 * @param id
 * @returns {AxiosPromise}
 */
export const httpDeleteSurvey = (id) => {
    const url = "/surveys/" + id;
    return axios.delete(url);
};

/**
 * get the whole template list
 * @returns {*}
 */
export const httpGetTemplateList = () => {
    const url = "/templates";
    return get(url);
};

/**
 * create a new template
 * @returns {*}
 */
export const httpCreateTemplateName = (title) => {
    const url = "/templates";
    return post(url, {title: title});
};

/**
 * edit the specified template title
 * @returns {*}
 */
export const httpEditTemplateName = (id, title) => {
    const url = "/templates/" + id;
    return put(url, {title: title});
};

/**
 * to delete a specific template
 * @param id
 * @returns {AxiosPromise}
 */
export const httpDeleteTemplate = (id) => {
    const url = `/templates/${id}`;
    return axios.delete(url);
};

/**
 * save a survey after an edit
 * @param survey
 * @returns {*}
 */
export const httpSaveSurvey = (survey) => {
    const url = "/surveys";
    return post(url, {title: survey.title, json: survey.survey, template_id: survey.templateId});
};
