import React from 'react';
import {NavLink} from "react-router-dom";

const Modal = (props) => (
    <div>
        <div className="overlay" onClick={props.onClose}/>
        <div className="center-modal">
            <div className="modal-body">
                <h4 className="text-center">Revision saved as </h4>
                <h6 className="text-center">
                    {props.title}
                </h6>
                <div className="btns-container">
                    <NavLink to={"/dasboard"} type="button" className="btn btn-view" >
                        Back to Dashboard
                    </NavLink>
                    <button type="button" className="btn btn-delete" onClick={props.onClose}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
);

export default Modal;
