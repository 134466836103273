import * as actionTypes from '../actions/usersActions';

const initialState = {
	currentUser: null,
};

const usersReducer = (state = initialState, action) => {

	switch (action.type) {
		case actionTypes.SET_LOGGED_USER:
			localStorage.setItem('user', JSON.stringify(action.currentUser));
            if (window.customerlySettings) {
                window.customerlySettings.user_id =  action.currentUser.doctor_code;
                window.customerlySettings.name = `${action.currentUser.first_name} ${action.currentUser.last_name}`;
                window.customerlySettings.email = action.currentUser.email;
            }
			return {
				...state,
				currentUser: action.currentUser,
			};
		case actionTypes.UPDATE_USER:
			localStorage.setItem('user', JSON.stringify(action.currentUser));
			return {
				...state,
				currentUser: action.currentUser,
			};
		case actionTypes.LOGOUT:
			localStorage.removeItem('user');
			localStorage.removeItem('token');
			return {
				...state,
				currentUser: null,
			};
		default:
			return state;
	}
};

export default usersReducer;
