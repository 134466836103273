import React, {Component} from 'react';
import Layout from "../Layout/Layout.component";

class PublicLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render(){
        return <Layout {...this.props} />
    }

}

export default PublicLayout;
