import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import * as styles from '../theme.js';
import {useParams} from 'react-router';
import {useForm} from 'react-hook-form';

import Spinner from "../Spinner.js";
import axios from "axios";
import {toMoney, toYYMMDD} from "../../Helpers/common";


const useStyles = createUseStyles({
    root: {
        position: 'relative',
        margin: [40, 0],
    },

    title: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: 20,
        fontWeight: 'bold',
        color: '#3e515e',
        textAlign: 'center',
        padding: 30,
        marginBottom: 38,
        borderBottom: '1px solid #e4e7eb',
    },

    form: {
        ...styles.paper,
        width: 500,
        margin: [0, 'auto'],
        padding: '24px',
        '& hr': {
            width: 'calc(100% + 46px)',
            marginLeft: -24,
            marginTop: 0, //tofix: resetter
            marginBottom: 0, //tofix: resetter
            gridColumn: '1 / span 2',
        },
        '& textarea': {
            cursor: "auto",
            width: "100%",
            padding: "8px"
        },

        '& svg': {
            width: 20,
            fill: '#3e515e',
        },

        '& label': {
            height: 'max-content',
            fontFamily: "'Titillium Web', sans-serif",
            fontSize: 16,
            color: '#3e515e',
            marginBottom: 6,
            // in case label has multiple elements inside
            display: 'grid',
            gridAutoFlow: 'column',
            gridAutoColumns: 'max-content',
            gridColumnGap: '10px',
        },

        '& label[data-inline]': {
            height: 'max-content',
            display: 'grid',
            gridAutoFlow: 'column',
            gridAutoColumns: 'auto',
            alignItems: 'center',
            justifyContent: 'left',
            gridColumnGap: '14px',
            marginBottom: 4,
        },

        '& label > span': {
            fontFamily: "'Titillium Web', sans-serif",
            fontWeight: 'normal',
        },

        '& button': styles.button,
    },

    /*buttons: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'max-content max-content 1fr',
        gridColumnGap: '20px',
        gridColumn: '1 / span 2',
        gridAutoFlow: 'column',

        '& > button': {
            justifySelf: 'right',
        },
    },*/

    error: {
        color: 'red',
        display: 'grid',
        marginTop: 8
    },

    recap: {
        ...styles.paper,
        width: "800px",
        margin: "0 auto",
        display: "grid",
        height: "auto",
        padding: "24px",
        '& hr': {
            width: '100%',
        },
        '& h4': {
            display: "flex",
            fontSize: "18px",
            alignItems: "center",
            justifyContent: "start",
            '& span': {
                fontSize: "20px", marginLeft: "8px", fontWeight: "600"
            },
        },
    },
    total: {
        fontSize: '20px!important',
        color: 'green'
    },
    emails_recap: {
        display: 'grid',
        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 264px))",
        gridColumnGap: '16px'
    },
    email_box: {
        '& h4': {
            display: "flex",
            fontSize: "18px",
            alignItems: "center",
            justifyContent: "start",
            '& span': {
                fontSize: "20px", marginLeft: "8px", fontWeight: "600"
            }
        },
        '& ul': {
            border: "1px solid #ccc",
            padding: "8px",
            listStyle: "none",
            borderRadius: "4px",
            overflowX: "hidden",
            overflowY: "scroll",
            maxHeight: '300px',
            '& li': {
                color: '#888',
                fontSize: '14px',
            },
        },
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        '& button': {
            ...styles.button,
            '&:disabled': {
                backgroundColor: '#888',
            },
        },
    }
});

const defaultValues = {};

const BusinessOrder = ({history}) => {
    const classes = useStyles();
    const {accountId} = useParams();

    const {register, handleSubmit, setError, errors, reset} = useForm({
        mode: 'onBlur',
        validateCriteriaMode: 'all',
        nativeValidation: false, // in order to use datalist with multiple values 1) type=email with native validation disabled 2) multiple=true
        defaultValues: undefined,
    });
    // loading
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [orderRecap, setOrderRecap] = useState(null);
    const [emails, setEmails] = useState(null);

    useEffect(() => {

    }, []);

    const onSubmit = async values => {
        console.log(values)
        try {
            setLoading(true);
            setEmails(values.emails);
            const {data} = await axios.post('/order/preview', values);
            console.log(data);
            setOrderRecap(data);
            setStep(2);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                Object.keys(error.response.data.error).map(e => {
                    setError(e, 'error', error.response.data.error[e])
                })
            } else {
                window.alert('Si è verificato un errore.');
            }
        } finally {
            setLoading(false);
        }
    };

    const onBackStep = () => {
        reset({emails: emails});
        setOrderRecap(null);
        setStep(1);
    };

    const onConfirmOrder = async () => {
        try {
            setLoading(true);
            const {response} = await axios.post('/order', {emails: emails});
            console.log('completed:', response);
            history.push('/business-order-list')
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    if (step === 1) {
        return <div className={classes.root}>
            {loading && <Spinner center={true} overlay={true}/>}

            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                {!!accountId && <input type={'hidden'} name={'id'} value={accountId} ref={register}/>}
                <div style={{margin: '0 0 32px'}}>
                    <h2 className={classes.title}>Inserimento utenze</h2>
                    <p className={classes.subtitle}>
                        Inserisci di seguito gli indirizzi email dei dipendenti da associare, <strong>separati da virgola.
                        </strong>
                    </p>
                    <p className={classes.subtitle}>
                        Ti ricordiamo, inoltre, che le suddette email saranno aggiunte a quelle registrate in precedenza.
                    </p>
                </div>
                <div className={classes.control}>
                    <label>Indirizzi email*</label>
                    <textarea name={'emails'} placeholder={'test@gmail.com,test2@gmail.com...'}
                              rows="5" cols="20" ref={register} required={true}/>
                    {errors.first_name && <small className={classes.error}>{errors.first_name.message}</small>}
                </div>

                <div className={classes.buttons}>
                    <button>Avanti</button>
                </div>
            </form>
        </div>
    } else {
        /* already_exist_emails: []
         amount_per_email: 2
         count_already_exist_emails: 0
         count_double_emails: 0
         count_invalid_emails: 0
         count_total_emails: 2
         count_unique_emails: 2
         count_valid_emails: 2
         invalid_emails: []
         total_amount: 4
         valid_emails: ["test@gmail.com", "test6@gmail.com"]
         0: "test@gmail.com"
         1: "test6@gmail.com"*/
        return (
            <div className={classes.root}>
                {<div className={classes.recap}>
                    <h2>Recap Ordine</h2>
                    <p>
                        Questo è il risultato dell'analisi relativa agli indirizzi inseriti.
                        Controlla i risultati seguenti e conferma il tuo ordine, oppure
                        torna al passo precedente per correggere eventuali errori.
                    </p>
                    <hr/>
                    <h4>Totale email inserite: <span>{orderRecap.count_unique_emails}</span></h4>
                    <hr/>
                    <div className={classes.emails_recap}>
                        {orderRecap.count_valid_emails > 0 && <div className={classes.email_box}>
                            <h4>Email valide: <span>{orderRecap.count_valid_emails}</span></h4>
                            <ul>
                                {orderRecap.valid_emails.map((item, index) => {
                                    return <li key={index}>{item}</li>
                                })}
                            </ul>
                        </div>}
                        {orderRecap.count_already_exist_emails > 0 && <div className={classes.email_box}>
                            <h4>Email già esistenti: <span>{orderRecap.count_already_exist_emails}</span></h4>
                            <ul>
                                {orderRecap.already_exist_emails.map((item, index) => {
                                    return <li key={index}>{item}</li>
                                })}
                            </ul>
                        </div>}
                        {orderRecap.count_invalid_emails > 0 && <div className={classes.email_box}>
                            <h4>Email non valide: <span>{orderRecap.count_invalid_emails}</span></h4>
                            <ul>
                                {orderRecap.invalid_emails.map((item, index) => {
                                    return <li key={index}>{item}</li>
                                })}
                            </ul>
                        </div>}
                    </div>
                    <hr/>
                    <h4>
                        Totale costo mensile
                        <span className={classes.total}> {toMoney(orderRecap.total_amount_without_discount)} </span>
                    </h4>
                    <h4>
                        Totale costo ordine
                        <span className={classes.total}> {toMoney(orderRecap.total_amount)} </span>
                    </h4>
                    <small>(calcolato dal {toYYMMDD(new Date())})</small>
                    <hr/>
                    <div className={classes.buttons}>
                        <button onClick={() => onBackStep()}> Indietro</button>
                        <button disabled={orderRecap.count_valid_emails < 1}
                                onClick={() => onConfirmOrder()}> Conferma
                        </button>
                    </div>
                </div>}
            </div>
        );
    }
};

export default BusinessOrder;
